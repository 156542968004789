import {UploadProvider} from '../../../pages/dashboard/profile';
import styled from 'styled-components';

const Card = styled.p`
  padding: 18px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid #EBEBE8;
  background: #F5F5F5;
  color: #636360;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
`;

const ContainerStyled = styled.div<{}>`
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ErrorMessageContainerStyled = styled.div<{}>`
  width: 100%;

  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.2px;

  span {
    color: #FF3B30;
  }
`;

const FileInput = (
  {
    errorMessage="",
    hasError = false,
    label,
    name,
    style=undefined,
    onChange,
    value,
    maxFileSize = 5*1024*1024,
    ...rest
  }
) => {

  const handleSuccess = (args) => {
    onChange(args);
  };

  return (
    <ContainerStyled style={style}>
      <UploadProvider
        id={name}
        name={name}
        onSuccess={handleSuccess}
        inputProps={rest}
      >
        <Card>
          {value?.filename || label}
        </Card>
      </UploadProvider>
      {hasError && errorMessage && (
        <ErrorMessageContainerStyled>
          <span>{errorMessage}</span>
        </ErrorMessageContainerStyled>
      )}
    </ContainerStyled>
  );
};

export default FileInput;