import * as yup from "yup";

const loginFormValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("errors.form.invalid.email")
    .required("errors.form.required.field"),
  password: yup.string().min(6).required("errors.form.required.field"),
});

export default loginFormValidationSchema;
