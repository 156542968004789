import styled from 'styled-components';

const ButtonStyled = styled.button<{ $outlined?: boolean; $display?: string; }>`
  //padding: var(--M) var(--XXL) var(--M) var(--XXL);
  //border-radius: var(--Rounded);
  --button-ui-color: ${props => props.disabled ? '#DADADA' : '#FFFFFF'};
  --button-ui-background: ${props => props.disabled ? '#F5F5F5' : '#1D1D1B'};
  --button-ui-border-color: var(--button-ui-background);

  ${props => !props.disabled && `
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  `}
  
  ${props => props.$display === 'secondary' && `
    --button-ui-color: #636360;
    --button-ui-background: ${props.disabled ? 'none' : '#EDEDED'};
    --button-ui-border-color: var(--button-ui-background);
    box-shadow: none;
  `}
  ${props => props.$display === 'accent' && `
    --button-ui-color: #000000;
    --button-ui-background: var(--Primary-Gradient-PG, linear-gradient(76deg, #ADE82F 0%, #E6F728 100%));
    --button-ui-border-color: #00000000;
    box-shadow: 0px 4px 16px 0px rgba(125, 175, 19, 0.30), 4px -4px 10px 0px rgba(125, 175, 19, 0.40) inset;
  `}

  color: ${(props) => props.$outlined ? '#1D1D1B' : 'var(--button-ui-color)'};
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
  border: 1px solid var(--button-ui-border-color);

  /* Property 1=Default */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 10px;
  background: ${(props) => props.$outlined ? 'none' : 'var(--button-ui-background)'};
  
  border-radius: 99999px;
  
  /* Mobile/Body */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */
  letter-spacing: -0.2px;
  box-sizing: border-box;
`;

const Button = ({...rest}) => {
  return (
    <ButtonStyled
      {...rest}
    />
  );
};

export default Button;