import { AxiosResponse } from "axios";
import { apiNemoClient as apiClient } from "../config/clients";
import { API_NEMO_ROUTES } from "../config/config";
import {APIListPayload, APIListResponse} from '../_helpers/api-list-types';
import {apiListPayloadGenerator} from '../_helpers/api-list-payload-generator';
import {APIQuote} from '../_helpers/api-types';

export async function getQuotes(payload?: APIListPayload): Promise<APIListResponse<APIQuote>> {
  const { data } = await apiClient.get<AxiosResponse<APIListResponse<APIQuote>>>(
    `${API_NEMO_ROUTES.QUOTES}${apiListPayloadGenerator(payload)}`
  );

  return data;
}

export async function getQuote(id: number): Promise<APIQuote> {
  const { data } = await apiClient.get<AxiosResponse<APIQuote>>(
    `${API_NEMO_ROUTES.QUOTES}/${id}`
  );

  return data;
}

export async function updateQuote(formData : Partial<APIQuote>): Promise<APIQuote> {
  const {id} = formData as {id: number};
  const { data } = await apiClient.put<object, AxiosResponse<APIQuote>>(
    `${API_NEMO_ROUTES.QUOTES}/${id}`,
    formData
  );

  return data;
}

export async function createQuote(formData : Partial<APIQuote>): Promise<APIQuote> {
  const { data } = await apiClient.post<object, AxiosResponse<APIQuote>>(
    `${API_NEMO_ROUTES.QUOTES}`,
    formData
  );

  return data;
}

export async function deleteQuote(id : number): Promise<null> {
  const { data } = await apiClient.delete<AxiosResponse<null>>(
    `${API_NEMO_ROUTES.QUOTES}/${id}`
  );

  return data;
}