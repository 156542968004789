import * as yup from "yup";

const nameFormValidationSchema = yup.object().shape({
  first_name: yup.string().min(1).required("errors.form.required.field"),
  last_name: yup.string().min(1).required("errors.form.required.field"),
  birth_date: yup.string().required("errors.form.required.field"),
  student: yup.boolean(),
});

const professionalNumberFormValidationSchema = yup.object().shape({
  professional_number: yup.string().min(1).required("errors.form.required.field"),
});

const sponsorFormValidationSchema = yup.object().shape({
  sponsor_code: yup.string().length(5),
});

const credentialsFormValidationSchema = yup.object().shape({
  email: yup.string().email().min(1).required("errors.form.required.field"),
  password: yup.string().min(6).required("errors.form.required.field"),
  terms_of_use_accepted_at: yup.string().min(1).required("errors.form.required.field"),
});

const studentSchoolFormValidationSchema = yup.object().shape({
  school_name: yup.string().min(1).required("errors.form.required.field"),
  school_country: yup.string().min(1).required("errors.form.required.field"),
});

const studentMailAndDocumentsFormValidationSchema = yup.object().shape({
  email: yup.string().email().min(1).required("errors.form.required.field"),
  attachments: yup.array().of(
    yup.object().shape({
      filename: yup.string().required("errors.form.required.field"),
      url: yup.string().required("errors.form.required.field"),
      signed_id: yup.string().required("errors.form.required.field"),
    })
  ).min(1).required("errors.form.required.field"),
});

export {
  nameFormValidationSchema,
  professionalNumberFormValidationSchema,
  credentialsFormValidationSchema,
  studentSchoolFormValidationSchema,
  studentMailAndDocumentsFormValidationSchema,
  sponsorFormValidationSchema,
};
