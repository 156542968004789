import React from 'react';
import NemoRouter from './router';
import {ThemeProvider} from 'styled-components';
import theme from './components/_ui/_styles/theme';
import GlobalStyle from './components/_ui/_styles/global_style';
import "./_translations/i18n";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle/>
      <NemoRouter/>
    </ThemeProvider>
  );
}

export default App;
