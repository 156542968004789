import {useMutation, useQuery, useQueryClient} from "react-query";
import {UserAPIService} from "../../_services/api";
import {usersKeys} from './queries';
import {APIListPayload, APIListResponse} from '../../_services/api/_helpers/api-list-types';
import {APIUser} from '../../_services/api/auth/types';

export function useUsers(payload?: APIListPayload) {
  const { refetch, isLoading, isError, error, data } = useQuery(
    [usersKeys.users, payload],
    (formData : object) => UserAPIService.getUsers(payload),
    {
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    getUsers: refetch,
    users: data,
    isLoading,
    isError,
    error,
  };
}

export function useUser({id}) {
  const queryClient = useQueryClient();
  const { refetch, isLoading, isError, error, data } = useQuery(
    usersKeys.users,
    (formData : object) => UserAPIService.getUser(id),
    {
      onSuccess: async (data) => {
        queryClient.setQueryData(
          usersKeys.users,
          (users: APIListResponse<APIUser>) => (
            {
              ...users,
              //@ts-ignore
              list: [...(users?.list || []).map(c => c.id !== data?.id ? c : data)]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
      cacheTime: 250,
      refetchOnMount: "always",
    }
  );
  return {
    getUser: refetch,
    user: data,
    isLoading,
    isError,
    error,
  };
}

export function useUpdateUser() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    usersKeys.users,
    (formData: Partial<APIUser>) => UserAPIService.updateUser(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          usersKeys.users,
          (users: APIListResponse<APIUser>) => (
            {
              ...(users || {}),
              //@ts-ignore
              list: [...(users?.list || []).map(c => c.id !== variables?.id ? c : data)]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    updateUser: mutate,
    isLoading,
    isError,
    error,
  };
}

export function useDeleteUser() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess, data, isError, error } = useMutation(
    usersKeys.users,
    (id: number) => UserAPIService.deleteUser(id),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          usersKeys.users,
          (users: APIListResponse<APIUser>) => ({
            ...(users || {}),
            list: [...(users?.list || []).filter(c => c.id !== variables)]
          })
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    deleteUser: mutate,
    isLoading,
    isSuccess,
    isError,
    response: data,
    error,
  };
}