import {jwtDecode} from "jwt-decode";

//TODO move this interface in a more appropriate file
interface JWT {
  exp: number;
}

const AUTH_TOKEN_KEY = "nemo-api-auth-token" as const;

export async function getAuthToken(): Promise<string | null> {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);

  if (token && isTokenExpired(token)) {
    await revokeAuthToken();
    return null;
  }

  return token || null;
}

//Register auth token in phone storage
export async function setAuthToken(token: string): Promise<void> {
  return localStorage.setItem(AUTH_TOKEN_KEY, token);
}

//Remove auth token from phone storage
export async function revokeAuthToken(): Promise<void> {
  return localStorage.removeItem(AUTH_TOKEN_KEY);
}

export function isTokenExpired(token: string): boolean {
  const { exp } = jwtDecode<JWT>(token);

  //Date.now returns date as milisecond
  //exp * 1000 is the conversion from exp (seconds) to milliseconds
  return Date.now() >= exp * 1000;
}

//Should parse the expiration date of the token
//If expired:
//   - revoke the current stored token from the secure store and from axios instance
//   - Chech if there is a refresh token:
//      - if true => fetch the corresponding API to get a new token
//   - should ask for a new token if there is a refresh token
