export const usersKeys = {
  users: ["users"] as const,
};
export const userKeys = {
  user: ["user"] as const,
  users: ["users"] as const,
  // profile: () => [...userKeys.user, "profile"] as const,
  // signup: () => [...userKeys.user, "signup"] as const,
  login: () => [...userKeys.user, "login"] as const,
};
