import React from "react";
import {APIUser} from '../../_services/api/auth/types';

export interface AuthenticationContextSchema {
  isLoggedIn: boolean | null;
  setIsLoggedIn: (status: boolean) => void;
  user: APIUser | null;
  setUser: (user: APIUser) => void;
  refetchUser: () => void;
}

const DEFAULT_AUTHENTICATION_CONTEXT: AuthenticationContextSchema = {
  isLoggedIn: false,
  setIsLoggedIn: () => false,
  user: null,
  setUser: () => false,
  refetchUser: () => false,
};

export const AuthenticationContext =
  React.createContext<AuthenticationContextSchema>(
    DEFAULT_AUTHENTICATION_CONTEXT
  );
