import { AxiosResponse } from "axios";
import { apiNemoClient as apiClient } from "../config/clients";
import { API_NEMO_ROUTES } from "../config/config";
import {APIListPayload, APIListResponse} from '../_helpers/api-list-types';
import {apiListPayloadGenerator} from '../_helpers/api-list-payload-generator';
import {APIDiagnostic} from '../_helpers/api-types';

export async function getDiagnostics(payload?: APIListPayload): Promise<APIListResponse<APIDiagnostic>> {
  const { data } = await apiClient.get<AxiosResponse<APIListResponse<APIDiagnostic>>>(
    `${API_NEMO_ROUTES.DIAGNOSTICS}${apiListPayloadGenerator(payload)}`
  );

  return data;
}

export async function getDiagnostic(id: number): Promise<APIDiagnostic> {
  const { data } = await apiClient.get<AxiosResponse<APIDiagnostic>>(
    `${API_NEMO_ROUTES.DIAGNOSTICS}/${id}`
  );

  return data;
}

export async function updateDiagnostic(formData : object): Promise<APIDiagnostic> {
  const {id} = formData as {id: number};
  const { data } = await apiClient.put<object, AxiosResponse<APIDiagnostic>>(
    `${API_NEMO_ROUTES.DIAGNOSTICS}/${id}`,
    formData
  );

  return data;
}

export async function createDiagnostic(formData : object): Promise<APIDiagnostic> {
  const { data } = await apiClient.post<object, AxiosResponse<APIDiagnostic>>(
    `${API_NEMO_ROUTES.DIAGNOSTICS}`,
    formData
  );

  return data;
}

export async function attachPathologyDetailsToDiagnostic(formData : object): Promise<APIDiagnostic> {
  const {id, diagnostic_version_id} = formData as {id: number, diagnostic_version_id: number};
  const { data } = await apiClient.post<object, AxiosResponse<APIDiagnostic>>(
    `${API_NEMO_ROUTES.DIAGNOSTICS}/${id}/diagnostic_versions/${diagnostic_version_id}/attach_pathology_details`,
    formData
  );

  return data;
}

export async function deleteDiagnostic(id : number): Promise<null> {
  const { data } = await apiClient.delete<AxiosResponse<null>>(
    `${API_NEMO_ROUTES.DIAGNOSTICS}/${id}`
  );

  return data;
}