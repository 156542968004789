import { AxiosResponse } from "axios";
import { apiNemoClient as apiClient } from "../config/clients";
import { API_NEMO_ROUTES } from "../config/config";
import {APIListPayload, APIListResponse} from '../_helpers/api-list-types';
import {apiListPayloadGenerator} from '../_helpers/api-list-payload-generator';
import {APIProfessional} from '../_helpers/api-types';

export async function getProfessionals(payload?: APIListPayload): Promise<APIListResponse<APIProfessional>> {
  const { data } = await apiClient.get<AxiosResponse<APIListResponse<APIProfessional>>>(
    `${API_NEMO_ROUTES.PROFESSIONALS}${apiListPayloadGenerator(payload)}`
  );

  return data;
}

export async function getProfessional(id: number): Promise<APIProfessional> {
  const { data } = await apiClient.get<AxiosResponse<APIProfessional>>(
    `${API_NEMO_ROUTES.PROFESSIONALS}/${id}`
  );

  return data;
}