import { AxiosResponse } from "axios";
import { apiNemoClient as apiClient } from "../config/clients";
import { API_NEMO_ROUTES } from "../config/config";
import {APIListPayload, APIListResponse} from '../_helpers/api-list-types';
import {apiListPayloadGenerator} from '../_helpers/api-list-payload-generator';
import {APIPathologyDetail} from '../_helpers/api-types';

export async function getPathologyDetails(payload?: APIListPayload): Promise<APIListResponse<APIPathologyDetail>> {
  const { data } = await apiClient.get<AxiosResponse<APIListResponse<APIPathologyDetail>>>(
    `${API_NEMO_ROUTES.PATHOLOGY_DETAILS}${apiListPayloadGenerator(payload)}`
  );

  return data;
}

export async function getPathologyDetail(id: number): Promise<APIPathologyDetail> {
  const { data } = await apiClient.get<AxiosResponse<APIPathologyDetail>>(
    `${API_NEMO_ROUTES.PATHOLOGY_DETAILS}/${id}`
  );

  return data;
}

export async function updatePathologyDetail(formData : object): Promise<APIPathologyDetail> {
  const {id} = formData as {id: number};
  const { data } = await apiClient.put<object, AxiosResponse<APIPathologyDetail>>(
    `${API_NEMO_ROUTES.PATHOLOGY_DETAILS}/${id}`,
    formData
  );

  return data;
}

export async function createPathologyDetail(formData : object): Promise<APIPathologyDetail> {
  const { data } = await apiClient.post<object, AxiosResponse<APIPathologyDetail>>(
    `${API_NEMO_ROUTES.PATHOLOGY_DETAILS}`,
    formData
  );

  return data;
}

export async function deletePathologyDetail(id : number): Promise<null> {
  const { data } = await apiClient.delete<AxiosResponse<null>>(
    `${API_NEMO_ROUTES.PATHOLOGY_DETAILS}/${id}`
  );

  return data;
}