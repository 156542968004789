import {useMutation, useQuery, useQueryClient} from "react-query";
import {BodyPartAPIService} from "../../_services/api";
import {bodyPartsKeys} from './queries';
import {APIListPayload, APIListResponse} from '../../_services/api/_helpers/api-list-types';
import {APIBodyPart} from '../../_services/api/_helpers/api-types';

export function useBodyParts(payload?: APIListPayload) {
  const { refetch, isLoading, isError, error, data } = useQuery(
    [bodyPartsKeys.bodyParts, payload],
    (formData : object) => BodyPartAPIService.getBodyParts(payload),
    {
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    getBodyParts: refetch,
    bodyParts: data,
    isLoading,
    isError,
    error,
  };
}

export function useBodyPart({id}) {
  const queryClient = useQueryClient();
  const { refetch, isLoading, isError, error, data } = useQuery(
    bodyPartsKeys.bodyParts,
    (formData : object) => BodyPartAPIService.getBodyPart(id),
    {
      onSuccess: async (data) => {
        queryClient.setQueryData(
          bodyPartsKeys.bodyParts,
          (bodyParts: APIListResponse<APIBodyPart>) => (
            {
              ...bodyParts,
              //@ts-ignore
              list: [...(bodyParts?.list || []).map(c => c.id !== data?.id ? c : data)]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
      cacheTime: 250,
      refetchOnMount: "always",
    }
  );
  return {
    getBodyPart: refetch,
    bodyPart: data,
    isLoading,
    isError,
    error,
  };
}

export function useCreateBodyPart() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    bodyPartsKeys.bodyParts,
    (formData : object) => BodyPartAPIService.createBodyPart(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          bodyPartsKeys.bodyParts,
          (bodyParts: APIListResponse<APIBodyPart>) => (
            {
              ...bodyParts,
              list: [...bodyParts?.list, data]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    createBodyPart: mutate,
    isLoading,
    isError,
    error,
  };
}

export function useUpdateBodyPart() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    bodyPartsKeys.bodyParts,
    (formData : object ) => BodyPartAPIService.updateBodyPart(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          bodyPartsKeys.bodyParts,
          (bodyParts: APIListResponse<APIBodyPart>) => (
            {
              ...bodyParts,
              //@ts-ignore
              list: [...(bodyParts?.list || []).map(c => c.id !== variables?.id ? c : data)]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    updateBodyPart: mutate,
    isLoading,
    isError,
    error,
  };
}

export function useDeleteBodyPart() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess, data, isError, error } = useMutation(
    bodyPartsKeys.bodyParts,
    (id: number) => BodyPartAPIService.deleteBodyPart(id),
    {
      onSuccess: async (data, variables) => {
        await queryClient.setQueryData(
          bodyPartsKeys.bodyParts,
          (bodyParts: APIListResponse<APIBodyPart>) => ({
            ...bodyParts,
            list: [...bodyParts?.list?.filter(c => c.id !== variables)]
          })
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    deleteBodyPart: mutate,
    isLoading,
    isSuccess,
    isError,
    response: data,
    error,
  };
}