import BottomBar from '../bottom-bar';
import {ReactComponent as HomeIcon} from '../../../_icons/home.svg';
import {ReactComponent as DrawIcon} from '../../../_icons/draw.svg';
import {ReactComponent as HistoryIcon} from '../../../_icons/history.svg';
import {ReactComponent as UserIcon} from '../../../_icons/user.svg';
import {ROUTES} from '../../../router/routes';

const items = [
  {
    Icon: HomeIcon,
    external: false,
    link: ROUTES.DASHBOARD(),
    target: undefined,
  },
  {
    Icon: DrawIcon,
    external: false,
    link: ROUTES.DIAGNOSTICS('new'),
    target: undefined,
  },
  {
    Icon: HistoryIcon,
    external: false,
    link: ROUTES.DIAGNOSTICS(),
    target: undefined,
  },
  {
    Icon: UserIcon,
    external: false,
    link: ROUTES.PROFILES('me'),
    target: undefined,
  },
];
const DashboardLayout = ({children}) => {
  return (
    <div style={{position: 'relative', paddingBottom: 100}}>
      <div>{children}</div>
      <BottomBar
        style={{position: 'fixed', bottom: -10, width: '100svw'}}
        items={items}
      />
    </div>
  );
};

export default DashboardLayout;