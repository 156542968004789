import {PinturaEditor} from '@pqina/react-pintura';
import {forwardRef, useImperativeHandle, useMemo, useRef, useState} from 'react';
import '@pqina/pintura/pintura.css';
import locale_fr_FR, {
  MarkupEditor as locale_markup_editor_fr_FR,
} from '@pqina/pintura/locale/fr_FR/core/fr_FR.js';

// Import the editor functionality
import {
  // Import the default image reader and writer
  createDefaultImageReader,
  createDefaultImageWriter,

  // The method used to register the plugins
  setPlugins,

  // The plugins we want to use
  plugin_annotate,

  // The user interface and plugin locale objects
  locale_en_gb,
  plugin_crop_locale_en_gb,
  plugin_annotate_locale_en_gb,

  // Because we use the annotate plugin we also need
  // to import the markup editor locale and the shape preprocessor
  markup_editor_locale_en_gb,
  createDefaultShapePreprocessor,

  // Import the default configuration for the markup editor
  markup_editor_defaults,
  createMarkupEditorToolStyles,
} from '@pqina/pintura';

// This registers the plugins with Pintura Image Editor
setPlugins(plugin_annotate);

// Create our editor configuration
const editorConfig = (width, height) => ({
  zoomMaskOpacity: 0,
  // This will read the image data (required)
  imageReader: createDefaultImageReader(),

  // This will write the output image
  imageWriter: createDefaultImageWriter({
    format: 'canvas',
    targetSize: {
      width: width / 4,
      height: height / 4,
      fit: 'contain',
      upscale: false,
    },
  }),

  // The markup editor default options, tools, shape style controls
  ...markup_editor_defaults,

  // This handles complex shapes like arrows / frames
  shapePreprocessor: createDefaultShapePreprocessor(),

  // The icons and labels to use in the user interface (required)
  locale: {
    ...locale_en_gb,
    ...locale_fr_FR,
    ...plugin_crop_locale_en_gb,
    ...plugin_annotate_locale_en_gb,
    ...markup_editor_locale_en_gb,
    ...locale_markup_editor_fr_FR,
  },
});
const stringifyImageState = (imageState) => {
  return JSON.stringify(imageState, (k, v) => (v === undefined ? null : v));
};

const parseImageState = (str) => {
  return JSON.parse(str);
};
const HumanWithPictura = forwardRef(({imageStateEditor, bodyPart, disabled = false}:any, ref) => {
  const [brushWidthInternal, setBrushWidthInternal] = useState(40);
  const componentRef = useRef(null);

  const undo = () => {
    const { editor } = componentRef.current;

    editor.history.undo();
  };
  const redo = () => {
    const { editor } = componentRef.current;

    editor.history.redo();
  };
  const clear = () => {
    const { editor } = componentRef.current;

    editor.history.revert();
  };
  const processImage = async () => {
    const { editor } = componentRef.current;

    const imageState = await editor.processImage();
    const canvas = imageState.dest;
    const imageStateStr = stringifyImageState(imageState.imageState);
    return {
      binary_area: generateReducedBytes(canvas)?.toString(),
      image_state_editor: JSON.parse(imageStateStr),
    };
  };

  const onLoad = async () => {
    if (!imageStateEditor) {
      return ;
    }
    const { editor } = componentRef.current;

    editor.history.write(imageStateEditor);
  };

  const generateReducedBytes = (canvas) => {
    if (canvas) {
      const bytes = canvas?.
      getContext('2d')?.
      getImageData(0,0, canvas.width, canvas.height)?.data;
      if (bytes) {
        const reducedBytes = new Uint8Array(bytes.length / 4 / 8);
        let array32 = new Uint32Array(bytes.length / 4);

        for (let i = 0; i < bytes.length; i += 4) {
          array32[i / 4] = bytes[i + 0] === 255 && bytes[i + 3] === 255 ? 1: 0;
        }

        for (let i = 0; i < reducedBytes.length; i++) {
          reducedBytes[i] = (
            ((array32[8 * i + 0] > 0 ? 1 : 0) << 7) +
            ((array32[8 * i + 1] > 0 ? 1 : 0) << 6) +
            ((array32[8 * i + 2] > 0 ? 1 : 0) << 5) +
            ((array32[8 * i + 3] > 0 ? 1 : 0) << 4) +
            ((array32[8 * i + 4] > 0 ? 1 : 0) << 3) +
            ((array32[8 * i + 5] > 0 ? 1 : 0) << 2) +
            ((array32[8 * i + 6] > 0 ? 1 : 0) << 1) +
            ((array32[8 * i + 7] > 0 ? 1 : 0) << 0)
          );
        }
        return reducedBytes;
      }
    }
  };

  const generateBytes = (reducedBytes) => {
    if (reducedBytes) {
      const array32 = new Uint32Array(reducedBytes.length * 8);
      const bytes = new Uint8Array(array32.length * 4);

      for (let i = 0; i < array32.length / 8; i++) {
        array32[8 * i + 0] = (reducedBytes[i] & 128) >> 7;
        array32[8 * i + 1] = (reducedBytes[i] & 64) >> 6;
        array32[8 * i + 2] = (reducedBytes[i] & 32) >> 5;
        array32[8 * i + 3] = (reducedBytes[i] & 16) >> 4;
        array32[8 * i + 4] = (reducedBytes[i] & 8) >> 3;
        array32[8 * i + 5] = (reducedBytes[i] & 4) >> 2;
        array32[8 * i + 6] = (reducedBytes[i] & 2) >> 1;
        array32[8 * i + 7] = (reducedBytes[i] & 1) >> 0;
      }

      for (let i = 0; i < bytes.length; i += 4) {
        bytes[i + 0] = array32[i] === 1 ? 255 : 0;
        bytes[i + 1] = 0;
        bytes[i + 2] = 0;
        bytes[i + 3] = array32[i] === 1 ? 255 : 0;
      }

      return (bytes);
      // const bytes = canvas?.
      // getContext('2d')?.
      // getImageData(0,0, canvas.width, canvas.height)?.data;
    }
  };

  // const compare = async (sources) => {
  //   const reducedBytes = await processImage();
  //
  //   const results = {};
  //
  //   Object.keys(sources).forEach(storageKey => {
  //     const storage32 = new Uint32Array(sources[storageKey]?.buffer || []);
  //     const res = new Uint32Array(reducedBytes.buffer).find((value, index) => (storage32[index] & value) !== 0);
  //     if (res) {
  //       const resDetails = reducedBytes.filter((value, index) => (sources[storageKey][index] & value) !== 0).length;
  //       // const total = reducedBytes.filter((value, index) => value !== 0).length;
  //       const total2 = sources[storageKey].filter((value, index) => value !== 0).length;
  //       results[storageKey] = resDetails / total2;
  //     }
  //   });
  //   return results;
  // };

  useImperativeHandle(ref,() => {
    return {
      setBrushWidth: setBrushWidthInternal,
      // compare,
      saveItem: generateReducedBytes,
      clear,
      undo,
      redo,
      processImage,
    };
  }, []);

  const toolStyles = useMemo(() => (
    // createMarkupEditorToolStyles({sharpie: {strokeWidth: brushWidthInternal}})
    createMarkupEditorToolStyles({sharpie: {strokeWidth: `${1+brushWidthInternal/100*5}%`}})
  ), [brushWidthInternal]);

  // if (!bodyPart.width || !bodyPart.height || !bodyPart.label){
  //   return null;
  // }

  return (
    <PinturaEditor
      {...editorConfig(bodyPart.width, bodyPart.height)}
      ref={componentRef}
      enableCanvasAlpha={true}
      enableUtils={false}
      enableToolbar={false}
      src={`/images/human/${bodyPart.label}.png`}
      markupEditorToolbar={[
        ['sharpie', 'Sharpie', {disabled: false}],
        ['eraser', 'Eraser', {disabled: false}],
      ]}
      onLoad={onLoad}
      markupEditorToolStyles={toolStyles}
      disabled={disabled}
      // enableTransparencyGrid={true}
      // onProcess={handleEditorProcess}
      // enablePan={inputType === 'pan'}
      // enablePanInput={true}
      // pan={{ x: 200, y: 500 }}
      panLimitGutterScalar={1}
      enablePanLimit={false}
      // zoomLevel={0.5}
      // enableZoom={inputType === 'zoom'}
      // enableAutoSelectMoveTool={true}
      // handleEvent={(event, details) => console.log(event, details)}
    />
  );
});

const MAX_WIDTH = window.innerWidth;
const MAX_HEIGHT = window.innerHeight;
const MIN_MATCH_PERCENT = 3;
const LOCALSTORAGE_KEY = 'data';

const HUMAN_IMAGES = {
  FRONT: { width: 1603 / 2, height: 4096 / 2 },
};

interface ICanvasStorage {
  [key: string]: Uint8Array;
}

// const HumanWithPicturaApp = () => {
//   const headerRef = useRef(null);
//   const mainRef = useRef(null);
//   const [offset, setOffset] = useState({x: 0, y: 0});
//   const [inputValue, setInputValue] = useState("");
//   const ratioWidth = Math.min(HUMAN_IMAGES.FRONT.width, (MAX_WIDTH - offset.x)) / HUMAN_IMAGES.FRONT.width;
//   const ratioHeight = Math.min(HUMAN_IMAGES.FRONT.height, (MAX_HEIGHT - offset.y)) / HUMAN_IMAGES.FRONT.height;
//   const mainRatio = Math.min(ratioWidth, ratioHeight);
//   const [storage, setStorage] = useState<ICanvasStorage>({});
//   const [results, setResults] = useState<object>({});
//
//   useLayoutEffect(() => {
//     const observer = new ResizeObserver((entries) => {
//       for (const entry of entries) {
//         const rect = headerRef.current.getBoundingClientRect();
//         setOffset({x: 0, y: rect.top + rect.height});
//       }
//     });
//
//     observer.observe(headerRef.current);
//
//     return () => observer.disconnect();
//   }, [headerRef]);
//
//   const loadFromStorage = () => {
//     const data = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) || '{}');
//
//     Object.keys(data).forEach((key:string) => {
//       const int32array: number[] = data[key].split(',').map((s : string) => Number(s));
//
//       const reducedBytes = new Uint8Array(int32array.length);
//       int32array.forEach((value, index) => {
//         reducedBytes[index] = value;
//       });
//       setStorage(prev => ({...prev, [key]: reducedBytes}));
//     });
//   };
//
//   useEffect(() => {
//     loadFromStorage();
//   }, []);
//
//   const saveInStorage = async() => {
//     const label = inputValue;
//     const reducedBytes = await mainRef.current?.processImage();
//     const newData = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) || '{}');
//     newData[label] = reducedBytes.toString();
//     localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newData));
//     setStorage(prev => ({...prev, [label]: reducedBytes}));
//     setInputValue("");
//     mainRef.current?.clear();
//   };
//
//   const resetAll = () => {
//     localStorage.removeItem(LOCALSTORAGE_KEY);
//     setStorage({});
//     setInputValue("");
//     mainRef.current?.clear();
//   };
//
//   return (
//     <div>
//       <div
//         ref={headerRef}
//         style={{height: '20svh'}}
//       >
//         <input type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)}/>
//         <button onClick={saveInStorage}>Save</button>
//         <select
//           defaultValue={40}
//           onChange={(e) => mainRef.current?.setBrushWidth(+e.target.value)}
//         >
//           <option value={20}>Petite</option>
//           <option value={40}>Moyenne</option>
//           <option value={60}>Grande</option>
//           <option value={80}>Très Grande</option>
//         </select>
//         <button onClick={() => mainRef.current?.clear()}>Clear</button>
//         <button onClick={() => mainRef.current?.undo()}>Undo</button>
//         <button onClick={() => mainRef.current?.redo()}>Redo</button>
//         <button onClick={async () => setResults(await mainRef.current?.compare(storage))}>Compare</button>
//         <button onClick={() => resetAll()}>Reset all</button>
//         <ul>
//           {
//             Object.keys(storage).sort((key1, key2) => (results[key2] || 0) - (results[key1] || 0)).map((key, index) => {
//               const percent = results[key] ? (results[key] * 100).toFixed(2) : 0;
//               const percentText = percent > MIN_MATCH_PERCENT ? `${percent}% : ` : '';
//               return (
//                 <li key={index}>{percentText}{key}</li>
//               );
//             })
//           }
//         </ul>
//       </div>
//       <div
//         style={{height: '80svh'}}
//       >
//         <HumanWithPictura
//           ref={mainRef}
//         />
//       </div>
//     </div>
//   );
// };

// export default HumanWithPicturaApp;
export {
  HumanWithPictura,
}