import { useEffect, useState } from "react";
import { AuthAPIService } from "../../_services/api";
import * as TokenService from "../../_services/utils/token";

export function useCheckToken() {
  const [isTokenStored, setIsTokenStored] = useState<boolean>(null);

  useEffect(() => {
    async function getStoredToken(): Promise<void> {
      const token = await TokenService.getAuthToken();

      if (token) {
        //Store the token in network provider header
        AuthAPIService.setAuthToken(token);
        setIsTokenStored(true);
      } else {
        setIsTokenStored(false);
      }
    }

    getStoredToken();
  }, []);

  return {
    isTokenStored,
  };
}
