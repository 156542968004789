import styled from 'styled-components';

const BulletPointContainer = styled.div`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const BulletPoint = styled.span<{$active?: boolean;}>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--Text-primary, #1D1D1B);;
  opacity: ${props => props.$active ? '1' : '0.3'};
`;

const BulletPoints = ({number, activeIndex}) => (
  <BulletPointContainer>
    {[...Array(number)].map((_, index) => (
      <BulletPoint
        key={index}
        $active={index === activeIndex}
      />
    ))}
  </BulletPointContainer>
);

export default BulletPoints;