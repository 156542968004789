import {APIListPayload, APISort} from './api-list-types';

type ApiListPayloadGenerator = (object?: APIListPayload) => string;

export const apiListPayloadGenerator: ApiListPayloadGenerator = (object) => {
  const values: Array<string> = [];

  Object.keys(object || {}).forEach((key: string) => {
    switch (key) {
      case 'filters':
        Object.keys(object.filters || {}).forEach((filterKey: string) => {
          object.filters[filterKey].forEach((filterValue: string) => {
            values.push(`filters[${filterKey}][]=${filterValue}`);
          });
        });
        break;
      case 'sorting':
        (object.sorting)?.forEach((sort: APISort) => {
          values.push(`sorting[][name]=${sort.name}`);
          values.push(`sorting[][direction]=${sort.direction}`);
        });
        break;
      default:
        values.push(`${key}=${object[key]}`);
    }
  });

  return values.length > 0 ? `?${values.join('&')}` : '';
}