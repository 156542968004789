import { useMutation } from "react-query";
import { AuthAPIService } from "../../_services/api";
import { userKeys } from "../user/queries";

export function useConfirmUser() {
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    userKeys.login(),
    (formData: object) => AuthAPIService.confirmUser(formData),
    {
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    confirmUser: mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  };
}
