import React from 'react';
import styled from 'styled-components';

/* Components */
export const ActiveUnderlineStyled = styled.div<{$thickness?: number; $duration?: number;}>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: ${props => props.$thickness}px;
  background-color: #E6F728;
  ${props => `transition: width ${props.$duration}ms ease, transform ${props.$duration}ms ease;`}
`;

const ActiveUnderline = ({ parentRef = undefined, activeIndex, thickness, transitionDuration }) => {
  const underline = React.useRef();
  let timeOut = null;

  const transformUnderline = () => {
    const parent = parentRef?.current;
    const target = parent?.children[activeIndex];

    if (!target) {
      return;
    }
    // waits the next frame before updating to get updated size of target
    timeOut = setTimeout(() => {
      if (!underline.current) {
        return;
      }
      //@ts-ignore
      underline.current.style.width = target.clientWidth + 'px';
      //@ts-ignore
      underline.current.style.transform = `translate3d(${target.offsetLeft}px, 0, 0)`;
    }, 1);
  };

  React.useEffect(() => {
    transformUnderline();
  });

  React.useEffect(() => {
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <ActiveUnderlineStyled
      ref={underline}
      $thickness={thickness}
      $duration={transitionDuration}
    />
  );
};

const TabbedNavigationStyled = styled.nav`
`;

const TabbedNavigationScroller = styled.div`
  position: relative;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  text-align: center;
  display: flex;

  -ms-overflow-style: none;
  &::-webkit-scrollbar{
    display: none;
  }
`;

const TabbedNavigationItem = styled.div<{$itemsMaxWidth?: string; $inactive?: boolean;}>`
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  font-weight: 600;
  cursor: pointer;
  padding: 8px 0;
  margin: 0 16px;
  border-bottom: 4px solid transparent;
  max-width: ${props => props.$itemsMaxWidth || '250px'};    
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  span {
    pointer-events: none;
    width: 100%;
  }

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${props => !props.$inactive && '#E6F728'};
  }

  a {
    color: #E6F728;
  }
`;

const TabbedNavigation = ({ items, defaultActive, ...props }) => {
  const navigationScroller = React.useRef();

  return (
    <TabbedNavigationStyled {...props}>
      <TabbedNavigationScroller ref={navigationScroller}>
        {items.map((item, index) => (
          <TabbedNavigationItem
            key={index}
            $itemsMaxWidth={props.itemsMaxWidth}
            onClick={(e) => {
              if (item.disabled) {
                return;
              }
              item.onClick && item.onClick(e);
            }}
          >
            {item.element}
          </TabbedNavigationItem>
        ))}
        {items?.length > 0 && (
          //@ts-ignore
          <ActiveUnderline
            parentRef={navigationScroller}
            activeIndex={defaultActive}
            thickness={3}
            transitionDuration={500}
          />
        )}
      </TabbedNavigationScroller>
    </TabbedNavigationStyled>
  );
};

export default TabbedNavigation;