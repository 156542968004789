import {useMutation, useQuery, useQueryClient} from "react-query";
import {UserRequestAPIService} from "../../_services/api";
import {userRequestsKeys} from './queries';
import {APIListPayload, APIListResponse} from '../../_services/api/_helpers/api-list-types';
import {APIUserRequest} from '../../_services/api/_helpers/api-types';

export function useUserRequests(payload?: APIListPayload) {
  const { refetch, isLoading, isError, error, data } = useQuery(
    [userRequestsKeys.userRequests, payload],
    (formData : object) => UserRequestAPIService.getUserRequests(payload),
    {
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    getUserRequests: refetch,
    userRequests: data,
    isLoading,
    isError,
    error,
  };
}

export function useUserRequest({id}) {
  const queryClient = useQueryClient();
  const { refetch, isLoading, isError, error, data } = useQuery(
    userRequestsKeys.userRequests,
    (formData : object) => UserRequestAPIService.getUserRequest(id),
    {
      onSuccess: async (data) => {
        queryClient.setQueryData(
          userRequestsKeys.userRequests,
          (userRequests: APIListResponse<APIUserRequest>) => (
            {
              ...userRequests,
              //@ts-ignore
              list: [...(userRequests?.list || []).map(c => c.id !== data?.id ? c : data)]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
      cacheTime: 250,
      refetchOnMount: "always",
    }
  );
  return {
    getUserRequest: refetch,
    userRequest: data,
    isLoading,
    isError,
    error,
  };
}

export function useCreateUserRequest() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    userRequestsKeys.userRequests,
    (formData : object) => UserRequestAPIService.createUserRequest(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          userRequestsKeys.userRequests,
          (userRequests: APIListResponse<APIUserRequest>) => (
            {
              ...userRequests,
              list: [...userRequests?.list, data]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    createUserRequest: mutate,
    isLoading,
    isError,
    error,
  };
}

export function useUpdateUserRequest() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    userRequestsKeys.userRequests,
    (formData : object ) => UserRequestAPIService.updateUserRequest(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          userRequestsKeys.userRequests,
          (userRequests: APIListResponse<APIUserRequest>) => (
            {
              ...userRequests,
              //@ts-ignore
              list: [...(userRequests?.list || []).map(c => c.id !== variables?.id ? c : data)]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    updateUserRequest: mutate,
    isLoading,
    isError,
    error,
  };
}

export function useDeleteUserRequest() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess, data, isError, error } = useMutation(
    userRequestsKeys.userRequests,
    (id: number) => UserRequestAPIService.deleteUserRequest(id),
    {
      onSuccess: async (data, variables) => {
        await queryClient.setQueryData(
          userRequestsKeys.userRequests,
          (userRequests: APIListResponse<APIUserRequest>) => ({
            ...userRequests,
            list: [...userRequests?.list?.filter(c => c.id !== variables)]
          })
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    deleteUserRequest: mutate,
    isLoading,
    isSuccess,
    isError,
    response: data,
    error,
  };
}