import * as React from 'react';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import {PageContainer} from '../../_ui/page';
import {useBalanceTransactions} from '../../../_hooks/balance-transaction/use-balance-transactions';
import {APIListPayload} from '../../../_services/api/_helpers/api-list-types';
import {forwardRef, useImperativeHandle} from 'react';
import {useTranslation} from 'react-i18next';
import {BalanceTransactionCategoryCaption, BalanceTransactionUnitCaption} from '../_commons/captions';

const COLUMNS = (handleCellClick, t) => [
  {
    label: 'ID',
    renderCell: (item) => item.id.toString(),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'User',
    renderCell: (item) => `${item.user?.first_name} ${item.user?.last_name}`,
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Catégorie',
    renderCell: (item) => (
      <BalanceTransactionCategoryCaption
        className={item.category}
      >
        {t(`enums.balance_transactions.category.${item.category}`)}
      </BalanceTransactionCategoryCaption>
    ),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Quantité',
    renderCell: (item) => item.quantity,
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Unité',
    renderCell: (item) => (
      <BalanceTransactionUnitCaption
        className={item.unit}
      >
        {t(`enums.balance_transactions.unit.${item.unit}`)}
      </BalanceTransactionUnitCaption>
    ),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Date',
    renderCell: (item) => (
      new Date(item.created_at).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    ),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Reason',
    renderCell: (item) => item.reason,
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
];

const BalanceTransactionsTable = forwardRef(({payload} : {payload?: APIListPayload}, ref) => {
  const {t} = useTranslation();
  const {balanceTransactions, getBalanceTransactions, isLoading} = useBalanceTransactions(payload || {
    sorting: [
      {
        name: 'created_at',
        direction: 'desc',
      },
    ],
  });

  const handleCellClick = (data) => {};

  const data = {nodes: balanceTransactions?.list || []};

  const theme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns: 50px 250px 120px repeat(2, 100px) 150px minmax(250px, 2fr);
      `,
      HeaderCell: `
        z-index: 0;
      `,
    },
  ]);

  useImperativeHandle(ref,() => {
    return {
      refresh: getBalanceTransactions,
    };
  }, []);

  return (
    <div style={{textAlign: 'left'}}>
      <CompactTable
        columns={COLUMNS(handleCellClick, t)}
        data={data}
        theme={theme}
        layout={{
          custom: true,
          horizontalScroll: true,
          fixedHeader: true,
        }}
      />
    </div>
  );
});

const BalanceTransactionsPage = () => (
  <PageContainer
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      gap: 20,
    }}
  >
    <h1>Transactions</h1>
    <BalanceTransactionsTable/>
  </PageContainer>
);

export default BalanceTransactionsPage;

export {
  BalanceTransactionsTable,
};