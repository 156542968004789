import isFunction from './is-function';

const groupBy = (items, key) => items.reduce(
  (result, item) => {
    const computedKey = isFunction(key) ? key(item) : item[key];
    return (
      {
        ...result,
        [computedKey]: [
          ...(result[computedKey] || []),
          item,
        ],
      }
    );
  },
  {},
);

export default groupBy;