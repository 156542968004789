import { AxiosResponse } from "axios";
import { apiNemoClient as apiClient } from "../config/clients";
import { API_NEMO_ROUTES } from "../config/config";
import {APIListPayload, APIListResponse} from '../_helpers/api-list-types';
import {apiListPayloadGenerator} from '../_helpers/api-list-payload-generator';
import {APIPathology, APIRequestPathologyMatches} from '../_helpers/api-types';

export async function getPathologies(payload?: APIListPayload): Promise<APIListResponse<APIPathology>> {
  const { data } = await apiClient.get<AxiosResponse<APIListResponse<APIPathology>>>(
    `${API_NEMO_ROUTES.PATHOLOGIES}${apiListPayloadGenerator(payload)}`
  );

  return data;
}

export async function getPathology(id: number): Promise<APIPathology> {
  const { data } = await apiClient.get<AxiosResponse<APIPathology>>(
    `${API_NEMO_ROUTES.PATHOLOGIES}/${id}`
  );

  return data;
}

export async function updatePathology(formData : object): Promise<APIPathology> {
  const {id} = formData as {id: number};
  const { data } = await apiClient.put<object, AxiosResponse<APIPathology>>(
    `${API_NEMO_ROUTES.PATHOLOGIES}/${id}`,
    formData
  );

  return data;
}

export async function createPathology(formData : object): Promise<APIPathology> {
  const { data } = await apiClient.post<object, AxiosResponse<APIPathology>>(
    `${API_NEMO_ROUTES.PATHOLOGIES}`,
    formData
  );

  return data;
}

export async function deletePathology(id : number): Promise<null> {
  const { data } = await apiClient.delete<AxiosResponse<null>>(
    `${API_NEMO_ROUTES.PATHOLOGIES}/${id}`
  );

  return data;
}

export async function getMatchesPathologies(payload: APIRequestPathologyMatches): Promise<object> {
  const { data } = await apiClient.post<object, AxiosResponse<object>>(
    `${API_NEMO_ROUTES.PATHOLOGIES}/matches`,
    payload
  );

  return data;
}

export async function addPathologyDetailToPathology(formData : object): Promise<APIPathology> {
  const {id} = formData as {id: number};
  const { data } = await apiClient.post<object, AxiosResponse<APIPathology>>(
    `${API_NEMO_ROUTES.PATHOLOGIES}/${id}/pathology_details`,
    formData
  );

  return data;
}

export async function removePathologyDetailToPathology(id: number, pathologyDetailId: number): Promise<null> {
  const { data } = await apiClient.delete<AxiosResponse<null>>(
    `${API_NEMO_ROUTES.PATHOLOGIES}/${id}/pathology_details/${pathologyDetailId}`
  );

  return data;
}