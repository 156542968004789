import React, {Fragment} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { rgba } from 'polished';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--XL, 32px);
  background: var(--Colors-Neutral-white, #FFF);
  padding: 32px 44px 36px 44px;
  gap: 24px;
  flex-shrink: 0;
  box-shadow: 0px 4px 250px 0px rgba(0, 0, 0, 0.25);
`;

const ReactModalAdapter = ({ className, withWrapper = false, children, ...props }) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  Modal.setAppElement('#root');

  const Wrapper = withWrapper ? ModalContent : Fragment;

  return (
    <Modal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      closeTimeoutMS={transitionMS + transitionDelayMS}
      {...props}
    >
      <Wrapper>
        {children}
      </Wrapper>
    </Modal>
  );
};
const transitionMS = 200;
const transitionDelayMS = 50;

// props.zIndexOffset for Broker's Dashboard Upload Doc, No Doc => Modal on top of flex
// TO DO: refactor and remove the first z-index prop (l.47 & 58)
const StyledModal = styled(ReactModalAdapter)`
  &__overlay {
    z-index: 500;
    position: fixed;
    box-sizing: border-box;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    // z-index: ${props => (props.zIndexOffset || 0) === 0 ? 'auto' : props.zIndexOffset};
    -webkit-overflow-scrolling: touch;
    background-color: ${props => props.overlay ? rgba('#999999', 0.9) : 'transparent'}; 

    ${props => props.withPadding && `
      padding: 10px 10px;
    `}
    
    /* Overlay animation */
    transition: opacity ${transitionMS}ms;
    opacity: 0;

    &[class*='--after-open'] {
      opacity: 1;
    }

    &[class*='--before-close'] {
      transition: opacity ${transitionMS}ms ${transitionDelayMS}ms;
      opacity: 0;
    }
  }

  &__content {
    position: relative;
    box-sizing: border-box;
    max-width: 100%;
    //max-height: 100%;
    outline: none;
    margin: auto;
    text-align: center;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);

    ${props => props.fullScreen && `
      max-height: 100vh;
      min-height: 100vh;
      width: 100%;
      overflow: hidden;
      border: none;
      border-radius: none;
      padding: 0px;
      box-shadow: none;
    `}
    
    ${props => props.popup && `
      background: #FFFFFF;
      border-radius: 16px;
    `}
    
    /* Content animation */
    transition: transform ${transitionMS}ms ${transitionDelayMS}ms,
                opacity ${transitionMS}ms ${transitionDelayMS}ms;
    transform: translateX(-150%);

    &[class*='--after-open'] {
      transform: none;
    }

    &[class*='--before-close'] {
      transition: transform ${transitionMS}ms, opacity ${transitionMS}ms;
      transform: translateX(-150%);
      opacity: 0;
    }
  }
`;

export default StyledModal;