import { AxiosResponse } from "axios";
import { apiNemoClient as apiClient } from "../config/clients";
import { API_NEMO_ROUTES } from "../config/config";
import {APIListPayload, APIListResponse} from '../_helpers/api-list-types';
import {apiListPayloadGenerator} from '../_helpers/api-list-payload-generator';
import {APIPathologyBlob} from '../_helpers/api-types';

export async function getPathologyBlobs(payload?: APIListPayload): Promise<APIListResponse<APIPathologyBlob>> {
  const { data } = await apiClient.get<AxiosResponse<APIListResponse<APIPathologyBlob>>>(
    `${API_NEMO_ROUTES.PATHOLOGY_BLOBS}${apiListPayloadGenerator(payload)}`
  );

  return data;
}

export async function getPathologyBlob(id: number): Promise<APIPathologyBlob> {
  const { data } = await apiClient.get<AxiosResponse<APIPathologyBlob>>(
    `${API_NEMO_ROUTES.PATHOLOGY_BLOBS}/${id}`
  );

  return data;
}

export async function updatePathologyBlob(formData : object): Promise<APIPathologyBlob> {
  const {id} = formData as {id: number};
  const { data } = await apiClient.put<object, AxiosResponse<APIPathologyBlob>>(
    `${API_NEMO_ROUTES.PATHOLOGY_BLOBS}/${id}`,
    formData
  );

  return data;
}

export async function createPathologyBlob(formData : object): Promise<APIPathologyBlob> {
  const { data } = await apiClient.post<object, AxiosResponse<APIPathologyBlob>>(
    `${API_NEMO_ROUTES.PATHOLOGY_BLOBS}`,
    formData
  );

  return data;
}

export async function deletePathologyBlob(id : number): Promise<null> {
  const { data } = await apiClient.delete<AxiosResponse<null>>(
    `${API_NEMO_ROUTES.PATHOLOGY_BLOBS}/${id}`
  );

  return data;
}

export async function getMatchesPathologyBlobs(payload: {binary_area: string; body_part_id: number;}): Promise<object> {
  const { data } = await apiClient.post<object, AxiosResponse<object>>(
    `${API_NEMO_ROUTES.PATHOLOGY_BLOBS}/matches`,
    payload
  );

  return data;
}