import styled from 'styled-components';
import {PageContainer} from '../../_ui/page';
import {ReactComponent as MascottePrimary} from '../../../assets/illustrations/mascotte-primary.svg';
import Button from '../../_ui/button';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../../../router/routes';

const LoginPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 480px;
  align-self: center;
  justify-content: space-between;
  max-height: 100svh;
  height: 100svh;
  padding: var(--XL, 24px) var(--XL, 24px) 56px var(--XL, 24px);
  box-sizing: border-box;
  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--L, 24px);
  background: var(--Background-Secondary, #FAFAFA);
  
  padding: var(--XL, 24px) var(--XXL, 32px);
  flex-shrink: 0;
  align-self: stretch;
  
  h1 {
    color: #000;
    text-align: center;
    lign-self: stretch;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Mobile/friendlyTitle */
    font-family: "Mochiy Pop One";
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 123.81% */
  }
  
  p {
    margin-top: 48px;
    max-width: 75%;
  }
`;

const ButtonsContainer = styled.form`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;

  > button {
    width: 100%;
  }
`;

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <LoginPageContainer>
      {/*<span/>*/}
      <Header>

        <MascottePrimary/>
        <h1>Bienvenue à toi</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pulvinar lobortis neque sit amet facilisis. Sed nec porttitor ligula.</p>

      </Header>
      {/*<InfoMessage*/}
      {/*  Illustration={<MascottePrimary/>}*/}
      {/*  Title={<h3>Bienvenue à toi</h3>}*/}
      {/*  Text={}*/}
      {/*/>*/}
      <ButtonsContainer>
        <Button
          onClick={() => navigate(ROUTES.LOGIN())}
        >
          Connexion
        </Button>
        <Button
          $display="secondary"
          onClick={() => navigate(ROUTES.SIGNUP())}
        >
          Inscription
        </Button>
      </ButtonsContainer>
      <div>
        <span>Informations Légales</span>
      </div>
    </LoginPageContainer>
  );
};

export default HomePage;