import {breakpoint} from 'styled-components-breakpoint';
import styled from 'styled-components';
import {ReactComponent as NemoLogo} from '../../../assets/logos/nemo-logo.svg';
import {Link} from 'react-router-dom';
import {ROUTES} from '../../../router/routes';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  //gap: 20px;
  //width: 100%;
  height: 100svh;
  //overflow-x: hidden;
  //overflow-y: hidden;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;

  svg {
    width: 100px;
    height: 25px;
  }
  
  ${breakpoint('sm' as any)`
    svg {
      width: 150px;
      height: 37px;
    }
  `}
`;

const Header = () => (
  <LogoContainer>
    <Link to={ROUTES.HOME()}>
      <NemoLogo/>
    </Link>
  </LogoContainer>
);

const PublicLayout = ({children}) => (
  <PageWrapper>
    <Header/>
    {children}
  </PageWrapper>
);

export default PublicLayout;

