import styled from 'styled-components';
import {useEffect, useRef, useState} from 'react';

const Cell = styled.div<{$focused: boolean;}>`
  width: 63px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: ${props => props.$focused ? 3 : 1}px solid ${props => props.$focused ? '#CEF37C' : '#EBEBE8'};
`;

const CellsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 8px;
  font-size: 24px;
  font-weight: 500;
  color: #1D1D1B;
`;

const CodeInputContainer = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 40px;
`;

const CodeInput = ({ value, name, onChange, length = 5 }) => {
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value || '');
  const inputRef = useRef(null);

  useEffect(() => {
    onChange(inputValue);
  }, [inputValue]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef.current]);

  const splittedValue = inputValue.split("");

  return (
    <CodeInputContainer>
      <input
        ref={inputRef}
        type="number"
        name={name}
        value={inputValue}
        style=
          {{
            width: '100%',
            height: 75,
            position: "absolute",
            opacity: 0,
          }}
        maxLength={length}
        onChange={(event) => setInputValue(event.target.value)}
        onFocus={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
      />
      <CellsContainer>
        {
          [...Array(length)].map((_, index) => (
            <Cell
              key={index}
              $focused={inputFocus && (
                splittedValue.length === index ||
                (splittedValue.length === length && index === length - 1)
              )}
            >
              {splittedValue[index]}
            </Cell>
          ))
        }
      </CellsContainer>
    </CodeInputContainer>
  );
}

export default CodeInput;