import * as yup from "yup";

const quoteFormValidationSchema = yup.object().shape({
  text: yup.string().
    min(20, "Minimum 20 caractères").
    max(100, "Maximum 100 caractères").
    required("errors.form.required.field"),
});

export {
  quoteFormValidationSchema,
};