import {LoginFields, SignupFields} from "../../../api/auth/types";
import { LoginFormFields } from "./types";

export function mapLoginFormFieldsToApi({
  email,
  password,
}: LoginFormFields): LoginFields {
  return {
    api_v1_users_user: {
      email,
      password,
    }
  };
}

export function mapSignupFormFieldsToApi(user): SignupFields {
  return {
    api_v1_users_user: user,
  };
}
