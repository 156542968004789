import styled from 'styled-components';
import {InputHTMLAttributes} from 'react';

const ContainerStyled = styled.div<{}>`
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InputContainerStyled = styled.div<{ $smallVerticalPadding?: boolean; }>`
  width: 100%;
  padding: ${(props) => props.$smallVerticalPadding ? '8px' : '16px'} 16px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid grey;
`;

const ErrorMessageContainer = styled.div<{}>`
  width: 100%;

  /* Mobile/Callout */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height, or 131% */
  letter-spacing: -0.2px;

  span {
    color: #FF3B30;
  }
`;

const LabelStyled = styled.label<{}>`
  width: 100%;

  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  letter-spacing: -0.2px;
  color: #636360;
`;

const InputStyled = styled.input<{ $hasError?: boolean; }>`
  width: 100%;
  border: none;
  background: none;
  appearance: none;
  outline: none;
  
  /* Mobile/Body */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */
  letter-spacing: -0.2px;

  color: ${(props) => props.$hasError ? '#FF3B30' : '#636360'};
`;

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errorMessage?: string;
  hasError?: boolean;
  smallVerticalPadding?: boolean;
}

const TextField = (
  {
    errorMessage,
    hasError = false,
    label,
    name,
    smallVerticalPadding = false,
    style,
    ...rest
  } : TextFieldProps
) => {
  return (
    <ContainerStyled style={style}>
      <InputContainerStyled
        $smallVerticalPadding={smallVerticalPadding}
      >
        {label && (
          <LabelStyled htmlFor={name}>
            {label}
          </LabelStyled>
        )}
        <InputStyled
          name={name}
          {...rest}
          $hasError={hasError}
        />
      </InputContainerStyled>
      {hasError && errorMessage && (
        <ErrorMessageContainer>
          <span>{errorMessage}</span>
        </ErrorMessageContainer>
      )}
    </ContainerStyled>
  );
};

export default TextField;

export {
  ErrorMessageContainer,
};