import {useMutation, useQuery, useQueryClient} from "react-query";
import {PathologyDetailAPIService} from "../../_services/api";
import {pathologyDetailsKeys} from './queries';
import {APIListPayload, APIListResponse} from '../../_services/api/_helpers/api-list-types';
import {APIPathologyDetail} from '../../_services/api/_helpers/api-types';

export function usePathologyDetails(payload?: APIListPayload) {
  const { refetch, isLoading, isError, error, data } = useQuery(
    [pathologyDetailsKeys.pathology_details, payload],
    (formData : object) => PathologyDetailAPIService.getPathologyDetails(payload),
    {
      useErrorBoundary: false,
      retry: false,
      cacheTime: 30,
    }
  );
  return {
    getPathologyDetails: refetch,
    pathology_details: data,
    isLoading,
    isError,
    error,
  };
}

export function usePathologyDetail({id}) {
  const { refetch, isLoading, isError, error, data } = useQuery(
    [pathologyDetailsKeys.pathology_details, id],
    (formData : object) => PathologyDetailAPIService.getPathologyDetail(id),
    {
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    getPathologyDetail: refetch,
    company: data,
    isLoading,
    isError,
    error,
  };
}

export function useCreatePathologyDetail() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    pathologyDetailsKeys.pathology_details,
    (formData : object) => PathologyDetailAPIService.createPathologyDetail(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          pathologyDetailsKeys.pathology_details,
          (pathology_details: APIListResponse<APIPathologyDetail>) => (
            {
              ...pathology_details,
              list: [...pathology_details?.list, data]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    createPathologyDetail: mutate,
    isLoading,
    isError,
    error,
  };
}

export function useUpdatePathologyDetail() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    pathologyDetailsKeys.pathology_details,
    (formData : object ) => PathologyDetailAPIService.updatePathologyDetail(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          pathologyDetailsKeys.pathology_details,
          (pathology_details: APIListResponse<APIPathologyDetail>) => (
            {
              ...(pathology_details || {}),
              //@ts-ignore
              list: [...(pathology_details?.list || []).map(c => c.id !== variables?.id ? c : data)]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    updatePathologyDetail: mutate,
    isLoading,
    isError,
    error,
  };
}

export function useDeletePathologyDetail() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess, data, isError, error } = useMutation(
    pathologyDetailsKeys.pathology_details,
    (id: number) => PathologyDetailAPIService.deletePathologyDetail(id),
    {
      onSuccess: async (data, variables) => {
        await queryClient.setQueryData(
          pathologyDetailsKeys.pathology_details,
          (pathology_details: APIListResponse<APIPathologyDetail>) => ({
            ...pathology_details,
            list: [...pathology_details?.list?.filter(c => c.id !== variables)]
          })
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    deletePathologyDetail: mutate,
    isLoading,
    isSuccess,
    isError,
    response: data,
    error,
  };
}