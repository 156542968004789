import DrawActionButton from '../draw-action-button';
import {useEffect, useState} from 'react';
import {ReactComponent as PenIcon} from '../../../_icons/pen.svg';
import styled from 'styled-components';

type BrushSizeSelectProps = {
  value: number;
  onChange: any;
  direction?: 'row' | 'column' | 'column-reverse' | 'row-reverse';
  optionsPosition?: 'absolute' | 'static';
};

const OptionsContainer = styled.div<{$withShadow: boolean;}>`
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid #00000000;
  ${props => props.$withShadow && `
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);
    border: 1px solid var(--Border-x-light, rgba(0, 0, 0, 0.03));
  `}
`;

const BrushSizeSelect = (
  {
    value,
    onChange,
    direction = 'column',
    optionsPosition = 'static'
  } : BrushSizeSelectProps
) => {
  const [brushOpen, setBrushOpen] = useState<boolean>(false);
  const [_brushSize, _setBrushSize] = useState<number>(value);

  useEffect(() => {
    onChange?.(_brushSize);
    setTimeout(() => setBrushOpen(false), 150);
  }, [_brushSize]);

  return (
    <OptionsContainer
      $withShadow={brushOpen && optionsPosition === 'static'}
      style={{
        display: 'flex',
        flexDirection: direction,
        backgroundColor: '#FFF',
        borderRadius: 12,
      }}
    >
      <DrawActionButton
        onClick={() => setBrushOpen(prev => !prev)}
      >
        <PenIcon/>
      </DrawActionButton>
      <div style={{position: 'relative'}}>
        {brushOpen && (
          <OptionsContainer
            $withShadow={brushOpen && optionsPosition === 'absolute'}
            style={{
              overflow: 'hidden',
              display: 'flex',
              flexDirection: direction,
              alignItems: 'center',
              justifyContent: 'center',
              position: optionsPosition,
              [['column-reverse'].includes(direction) ? 'bottom' : 'top']: 0,
              [['row-reverse'].includes(direction) ? 'right' : 'left']: 0,
              [['column','column-reverse'].includes(direction) ? 'right' : 'bottom']: 0,
              backgroundColor: '#FFF',
              boxSizing: 'border-box',
              zIndex: 2,
              borderRadius: 12,
            }}
          >
            {[90, 40, 10].map((size, index) => (
              <div
                key={index}
                style={{
                  boxSizing: 'border-box',
                  padding: 5,
                }}
              >
                <input
                  name="brushSize"
                  type="radio"
                  value={size}
                  onChange={() => _setBrushSize(size)}
                  checked={size === _brushSize}
                  style={{
                    margin: 0,
                    accentColor: '#99D41C',
                    width: size / 4 + 5,
                    height: size / 4 + 5,
                  }}
                />
              </div>
            ))}
          </OptionsContainer>
        )}
      </div>
    </OptionsContainer>
  );
};

export default BrushSizeSelect;