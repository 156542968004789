import {AxiosResponse} from "axios";
import { apiNemoClient as apiClient } from "../config/clients";
import { API_NEMO_ROUTES } from "../config/config";
import {APIUser} from '../auth/types';
import {APIListPayload, APIListResponse} from '../_helpers/api-list-types';
import {apiListPayloadGenerator} from '../_helpers/api-list-payload-generator';

export async function getUser(id?: number): Promise<APIUser> {
  const { data } = await apiClient.get<AxiosResponse<APIUser>>(
    `${API_NEMO_ROUTES.USERS}/${id || 'me'}`
  );

  return data;
}

export async function getUsers(payload?: APIListPayload): Promise<APIListResponse<APIUser>> {
  const { data } = await apiClient.get<AxiosResponse<APIListResponse<APIUser>>>(
    `${API_NEMO_ROUTES.USERS}${apiListPayloadGenerator(payload)}`
  );

  return data;
}

export async function updateUser(formData : Partial<APIUser>): Promise<APIUser> {
  // @ts-ignore
  const {id} = formData;
  const { data } = await apiClient.put<Partial<APIUser>, AxiosResponse<APIUser>>(
    `${API_NEMO_ROUTES.USERS}/${id}`,
    formData
  );

  return data;
}

export async function deleteUser(id : number): Promise<object> {
  const { data } = await apiClient.delete<AxiosResponse<object>>(
    `${API_NEMO_ROUTES.USERS}/${id}`
  );

  return data;
}


export async function getStripePortalSessionUser(id: number, redirect_url: string): Promise<{url: string}> {
  const { data } = await apiClient.get<AxiosResponse<{url: string}>>(
    `${API_NEMO_ROUTES.USERS}/${id || 'me'}/stripe_portal_session?redirect_url=${encodeURIComponent(redirect_url)}`
  );

  return data;
}

export async function getStripeCheckoutSessionUser(id: number, redirect_url: string): Promise<{url: string}> {
  const { data } = await apiClient.get<AxiosResponse<{url: string}>>(
    `${API_NEMO_ROUTES.USERS}/${id || 'me'}/stripe_checkout_session?redirect_url=${encodeURIComponent(redirect_url)}`
  );

  return data;
}
