import { AxiosResponse } from "axios";
import { apiNemoClient as apiClient } from "../config/clients";
import { API_NEMO_ROUTES } from "../config/config";
import {APIListPayload, APIListResponse} from '../_helpers/api-list-types';
import {apiListPayloadGenerator} from '../_helpers/api-list-payload-generator';
import {APIBalanceTransaction} from '../_helpers/api-types';

export async function getBalanceTransactions(payload?: APIListPayload): Promise<APIListResponse<APIBalanceTransaction>> {
  const { data } = await apiClient.get<AxiosResponse<APIListResponse<APIBalanceTransaction>>>(
    `${API_NEMO_ROUTES.BALANCE_TRANSACTIONS}${apiListPayloadGenerator(payload)}`
  );

  return data;
}

export async function getBalanceTransaction(id: number): Promise<APIBalanceTransaction> {
  const { data } = await apiClient.get<AxiosResponse<APIBalanceTransaction>>(
    `${API_NEMO_ROUTES.BALANCE_TRANSACTIONS}/${id}`
  );

  return data;
}

export async function updateBalanceTransaction(formData : object): Promise<APIBalanceTransaction> {
  const {id} = formData as {id: number};
  const { data } = await apiClient.put<object, AxiosResponse<APIBalanceTransaction>>(
    `${API_NEMO_ROUTES.BALANCE_TRANSACTIONS}/${id}`,
    formData
  );

  return data;
}

export async function createBalanceTransaction(formData : object): Promise<APIBalanceTransaction> {
  const { data } = await apiClient.post<object, AxiosResponse<APIBalanceTransaction>>(
    `${API_NEMO_ROUTES.BALANCE_TRANSACTIONS}`,
    formData
  );

  return data;
}

export async function deleteBalanceTransaction(id : number): Promise<null> {
  const { data } = await apiClient.delete<AxiosResponse<null>>(
    `${API_NEMO_ROUTES.BALANCE_TRANSACTIONS}/${id}`
  );

  return data;
}