import {usePathologyDetails} from '../../../_hooks/pathology-detail/use-pathology-details';
import {useState} from 'react';
import {Autocomplete} from '../../_ui/form';
import * as React from 'react';
import {PathologyDetailCategoryCaption} from '../_commons/captions';
import {useTranslation} from 'react-i18next';
import {APIPathologyDetail} from '../../../_services/api/_helpers/api-types';

type PathologyDetailSearchProps = {
  onChange: (pathologyDetail: APIPathologyDetail) => any;
};

const PathologyDetailSearch = ({onChange} : PathologyDetailSearchProps) => {
  const {t} = useTranslation();
  const [searchText, setSearchText] = useState<string>('');
  const {pathology_details} = usePathologyDetails({
    filters: {
      search: [searchText]
    },
    page_size: 20,
  });

  return (
    <Autocomplete
      name="search"
      label={"Recherche"}
      placeholder={"Symptôme, test, événement, observation, ..."}
      onTextChange={setSearchText}
      text={searchText}
      onChange={onChange}
      items={pathology_details?.list}
      Item={({item: pathologyDetail}) => (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '40px 100px 1fr',
            gap: 8,
            alignItems: 'center',
          }}
        >
          {pathologyDetail.id}
          <PathologyDetailCategoryCaption
            className={pathologyDetail.category}
          >
            {t(`enums.pathology_detail.category.${pathologyDetail.category}`)}
          </PathologyDetailCategoryCaption>
          {pathologyDetail.text}
        </div>
      )}
    />
  );
};

export default PathologyDetailSearch;