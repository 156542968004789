import Axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { API_ROUTES, ApiClientType } from './config';

type ReadRequest = <Response>(
  path: string,
  config?: AxiosRequestConfig
) => Promise<Response>;

type WriteRequest = <Request, Response>(
  path: string,
  object: Request,
  config?: AxiosRequestConfig
) => Promise<Response>;

interface ApiClient {
  get: ReadRequest;
  post: WriteRequest;
  put: WriteRequest;
  patch: WriteRequest;
  delete: ReadRequest;
  setToken: (token: string) => void;
  unsetToken: () => void;
}


export class ApiClientBase implements ApiClient {
  protected client: AxiosInstance;

  constructor(api: ApiClientType) {
    this.client = Axios.create({ baseURL: API_ROUTES[api].BASE });
  }

  get<Response>(route: string, config?: AxiosRequestConfig): Promise<Response> {
    return this.client.get(route, { signal: config?.signal, params: config?.params });
  }

  post<Request, Response>(route: string, data: Request, config?: AxiosRequestConfig): Promise<Response> {
    return this.client.post(route, data, { signal: config?.signal });
  }

  put<Request, Response>(route: string, data: Request, config?: AxiosRequestConfig): Promise<Response> {
    return this.client.put(route, data, { signal: config?.signal });
  }

  patch<Request, Response>(route: string, data: Request, config?: AxiosRequestConfig): Promise<Response> {
    return this.client.patch(route, data, { signal: config?.signal });
  }

  delete<Response>(route: string, config?: AxiosRequestConfig): Promise<Response> {
    return this.client.delete(route, { signal: config?.signal });
  }

  setToken(token: string) {
    this.client.defaults.headers.common["Authorization"] = `${token}`;
  }

  unsetToken() {
    delete this.client.defaults.headers.common["Authorization"];
  }

}