import { AxiosResponse } from "axios";
import { apiNemoClient as apiClient } from "../config/clients";
import { API_NEMO_ROUTES } from "../config/config";
import {APIListPayload, APIListResponse} from '../_helpers/api-list-types';
import {apiListPayloadGenerator} from '../_helpers/api-list-payload-generator';
import {APIBodyPart} from '../_helpers/api-types';

export async function getBodyParts(payload?: APIListPayload): Promise<APIListResponse<APIBodyPart>> {
  const { data } = await apiClient.get<AxiosResponse<APIListResponse<APIBodyPart>>>(
    `${API_NEMO_ROUTES.BODY_PARTS}${apiListPayloadGenerator(payload)}`
  );

  return data;
}

export async function getBodyPart(id: number): Promise<APIBodyPart> {
  const { data } = await apiClient.get<AxiosResponse<APIBodyPart>>(
    `${API_NEMO_ROUTES.BODY_PARTS}/${id}`
  );

  return data;
}

export async function updateBodyPart(formData : object): Promise<APIBodyPart> {
  const {id} = formData as {id: number};
  const { data } = await apiClient.put<object, AxiosResponse<APIBodyPart>>(
    `${API_NEMO_ROUTES.BODY_PARTS}/${id}`,
    formData
  );

  return data;
}

export async function createBodyPart(formData : object): Promise<APIBodyPart> {
  const { data } = await apiClient.post<object, AxiosResponse<APIBodyPart>>(
    `${API_NEMO_ROUTES.BODY_PARTS}`,
    formData
  );

  return data;
}

export async function deleteBodyPart(id : number): Promise<null> {
  const { data } = await apiClient.delete<AxiosResponse<null>>(
    `${API_NEMO_ROUTES.BODY_PARTS}/${id}`
  );

  return data;
}