import styled from 'styled-components';
import {ComponentProps, useRef, useState} from 'react';
import {ReactComponent as CopyIcon} from '../../../_icons/copy.svg';
import {ReactComponent as CheckCircleIcon} from '../../../_icons/check_circle.svg';

const CaptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #E1FCA6;
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px solid #ADE82F;
  font-size: 14px;
`;

interface CaptionProps extends ComponentProps<'div'> {
  copy?: boolean;
  copyContent?: string;
}

const Caption = ({copy, copyContent, children, ...rest} : CaptionProps) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const timer = useRef<NodeJS.Timeout | null>(null);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(copyContent || children?.toString());
    setIsCopied(true);
    clearTimeout(timer.current)
    timer.current = setTimeout(() => setIsCopied(false), 2000);
  };

  const handleClick = copy ? handleCopy : undefined;
  const Icon = isCopied ? CheckCircleIcon : CopyIcon;

  if ([null, undefined].includes(children)) {
    return <>{children}</>;
  }

  return (
    <CaptionContainer {...rest}>
      {children}
      {copy && (
        <Icon
          onClick={handleClick}
          width={12}
          height={12}
          style={{cursor: 'pointer'}}
        />
      )}
    </CaptionContainer>
  );
};

export default Caption;