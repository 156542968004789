import {useMutation, useQuery, useQueryClient} from "react-query";
import {BalanceTransactionAPIService} from "../../_services/api";
import {balanceTransactionsKeys} from './queries';
import {APIListPayload, APIListResponse} from '../../_services/api/_helpers/api-list-types';
import {APIBalanceTransaction} from '../../_services/api/_helpers/api-types';

export function useBalanceTransactions(payload?: APIListPayload) {
  const { refetch, isLoading, isError, error, data } = useQuery(
    [balanceTransactionsKeys.balanceTransactions, payload],
    (formData : object) => BalanceTransactionAPIService.getBalanceTransactions(payload),
    {
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    getBalanceTransactions: refetch,
    balanceTransactions: data,
    isLoading,
    isError,
    error,
  };
}

export function useBalanceTransaction({id}) {
  const queryClient = useQueryClient();
  const { refetch, isLoading, isError, error, data } = useQuery(
    balanceTransactionsKeys.balanceTransactions,
    (formData : object) => BalanceTransactionAPIService.getBalanceTransaction(id),
    {
      onSuccess: async (data) => {
        queryClient.setQueryData(
          balanceTransactionsKeys.balanceTransactions,
          (balanceTransactions: APIListResponse<APIBalanceTransaction>) => (
            {
              ...balanceTransactions,
              //@ts-ignore
              list: [...(balanceTransactions?.list || []).map(c => c.id !== data?.id ? c : data)]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
      cacheTime: 250,
      refetchOnMount: "always",
    }
  );
  return {
    getBalanceTransaction: refetch,
    pathology: data,
    isLoading,
    isError,
    error,
  };
}

export function useCreateBalanceTransaction() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    balanceTransactionsKeys.balanceTransactions,
    (formData : object) => BalanceTransactionAPIService.createBalanceTransaction(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          balanceTransactionsKeys.balanceTransactions,
          (balanceTransactions: APIListResponse<APIBalanceTransaction>) => (
            {
              ...(balanceTransactions || {}),
              list: [...(balanceTransactions?.list || []), data]
            }
          )
        );
        return data;
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    createBalanceTransaction: mutate,
    isLoading,
    isError,
    error,
  };
}

// export function useUpdateBalanceTransaction() {
//   const queryClient = useQueryClient();
//   const { mutate, isLoading, isError, error } = useMutation(
//     balanceTransactionsKeys.balanceTransactions,
//     (formData : object ) => BalanceTransactionAPIService.updateBalanceTransaction(formData),
//     {
//       onSuccess: async (data, variables) => {
//         queryClient.setQueryData(
//           balanceTransactionsKeys.balanceTransactions,
//           (balanceTransactions: APIListResponse<APIBalanceTransaction>) => (
//             {
//               ...balanceTransactions,
//               //@ts-ignore
//               list: [...(balanceTransactions?.list || []).map(c => c.id !== variables?.id ? c : data)]
//             }
//           )
//         );
//       },
//       useErrorBoundary: false,
//       retry: false,
//     }
//   );
//   return {
//     updateBalanceTransaction: mutate,
//     isLoading,
//     isError,
//     error,
//   };
// }

export function useDeleteBalanceTransaction() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess, data, isError, error } = useMutation(
    balanceTransactionsKeys.balanceTransactions,
    (id: number) => BalanceTransactionAPIService.deleteBalanceTransaction(id),
    {
      onSuccess: async (data, variables) => {
        await queryClient.setQueryData(
          balanceTransactionsKeys.balanceTransactions,
          (balanceTransactions: APIListResponse<APIBalanceTransaction>) => ({
            ...balanceTransactions,
            list: [...balanceTransactions?.list?.filter(c => c.id !== variables)]
          })
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    deleteBalanceTransaction: mutate,
    isLoading,
    isSuccess,
    isError,
    response: data,
    error,
  };
}