import styled from 'styled-components';
import {ReactNode} from 'react';

const InfoMessageHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--4, 16px);
  align-self: stretch;
  
  > {
    display: block;
  }
`;

const InfoMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 48px;
  padding: var(--XL, 24px) var(--XXL, 32px);
  border-radius: var(--L, 24px);
  //background: var(--Background-Secondary, #FAFAFA);
`;

const InfoMessage = (
  {Illustration, Title, Text} : {Illustration?: ReactNode, Title?: ReactNode, Text?: ReactNode}
) => (
  <InfoMessageContainer>
    {(Illustration || Title) && (
      <InfoMessageHeader>
        {Illustration}
        {Title}
      </InfoMessageHeader>
    )}
    {Text}
  </InfoMessageContainer>
);

export default InfoMessage;