import styled from 'styled-components';
import {PageContainer} from '../../../_ui/page';
import {useState} from 'react';
import {ReactComponent as MascottePrimary} from '../../../../assets/illustrations/mascotte-primary.svg';
import {useFormik} from 'formik';
import {INITIAL_FORM_VALUES} from './config';
import resetPasswordFormValidationSchema from './validation-schema';
import {Form, TextField} from '../../../_ui/form';
import Button from '../../../_ui/button';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {useUpdatePassword} from '../../../../_hooks/auth/use-update-password';
import InfoMessage from '../../../_ui/info-message';
import {ROUTES} from '../../../../router/routes';

const LoginPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  max-height: 100svh;
  height: 100svh;
  padding: var(--XL, 24px) var(--XL, 24px) 56px var(--XL, 24px);
`;

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  let [searchParams, _setSearchParams] = useSearchParams();
  const { updatePassword, isLoading, isSuccess } = useUpdatePassword();
  const { errors, handleChange, handleSubmit, values, isValid, touched, handleBlur } = useFormik({
    initialValues: {
      ...INITIAL_FORM_VALUES,
      reset_password_token: searchParams.get('reset_password_token'),
    },
    onSubmit: (formValues) => updatePassword(formValues),
    validationSchema: resetPasswordFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });
  const firstConnexion = searchParams.get('first_connexion') === '1';

  return (
    <LoginPageContainer>
      {isSuccess
        ? (
          <div
            style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: 100}}
          >
            <InfoMessage
              Illustration={<MascottePrimary/>}
              Title={<h1>Let’s go !</h1>}
              Text={(
                firstConnexion
                  ? <p>Ton mot de passe a été créé avec succès. Tu peux maintenant te connecter à ton compte.</p>
                  : <p>Ton mot de passe a été modifié avec succès. Tu peux maintenant te connecter à ton compte.</p>
              )}
            />
            <Form>
              <Button
                onClick={() => navigate(ROUTES.LOGIN())}
              >
                Connexion
              </Button>
            </Form>
          </div>
        ) : (
          <>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: 100}}>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginBottom: 50, textAlign: "center"}}>
                <h1>{firstConnexion ? "Crée ton mot de passe" : "Crée ton nouveau mot de passe"}</h1>
              </div>
              <Form>
                <TextField
                  name="password"
                  required
                  type="password"
                  placeholder={"Nouveau mot de passe"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  hasError={touched.password && !!errors.password}
                  errorMessage={errors.password}
                />
                <TextField
                  name="password_confirmation"
                  required
                  type="password"
                  placeholder={"Répète le mot de passe"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password_confirmation}
                  hasError={touched.password_confirmation && !!errors.password_confirmation}
                  errorMessage={errors.password_confirmation}
                />
                <Button
                  disabled={!isValid || isLoading}
                  onClick={handleSubmit}
                >
                  {firstConnexion ? "Créer mon compte" : "Réinitialiser"}
                </Button>
              </Form>
            </div>
            <div>
              <span>Un problème ? </span><Link to={""}>Contacter le support</Link>
            </div>
          </>
        )
      }
    </LoginPageContainer>
  );
};

export default ResetPasswordPage;