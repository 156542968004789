import {useEffect, useRef, useState} from 'react';
import TextField from '../text-field';
import styled from 'styled-components';

const ItemsContainer = styled.ul`
  margin: 0;
  padding: 0;
  text-align: left;
  background-color: #fff;
  box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  max-height: 350px;
  overflow-y: auto;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const ItemContainer = styled.li`
  margin: 0;
  list-style: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  
  &:hover {
    background-color: #CCCCCCCC;
  }
`;

const Autocomplete = (
  {
    onChange,
    onFocus = (event) => {},
    onClick = (event) => {},
    text,
    onTextChange,
    Item,
    items,
    ...rest
  }
) => {
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const wrapperRef = useRef();

  const handleClickOutside = e => {
    // @ts-ignore
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setInputFocused(false);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <div ref={wrapperRef}>
      <TextField
        required
        smallVerticalPadding
        autoComplete="off"
        {...rest}
        value={text}
        onChange={(e) => onTextChange(e.target.value)}
        onFocus={(e) => {
          setInputFocused(true);
          onFocus?.(e);
        }}
        onClick={(e) => {
          setInputFocused(true);
          onClick?.(e);
        }}
      />
      {inputFocused && items?.length > 0 && (
        <div
          style={{position: 'relative', zIndex: 1}}
        >
          <ItemsContainer>
            {items.map((item, index) => (
              <ItemContainer
                key={index}
                onClick={() => {
                  onChange?.(item);
                  setInputFocused(false);
                }}
              >
                <Item item={item}/>
              </ItemContainer>
            ))}
          </ItemsContainer>
        </div>
      )}
    </div>
  );
};

export default Autocomplete;