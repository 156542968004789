// import assert from "assert";
import { REACT_APP_API_URL } from '../../../_config/env';

// Block the build if this variable is not found
// This avoid us to ship a broken app in case of env error
// assert(REACT_APP_API_URL, "env variable not set: REACT_APP_API_URL");

export const API_BASE_PATH = "/api/v1";

export const API_CLIENTS_MAP = {
  NEMO: 'NEMO',
} as const;

export type ApiClientType = keyof typeof API_CLIENTS_MAP;

export const API_NEMO_ROUTES = {
  BASE: `${REACT_APP_API_URL}${API_BASE_PATH}`,
  USERS: "/users",
  LOGIN: "/users/login",
  LOGOUT: "/users/logout",
  SIGNUP: "/users/signup",
  PASSWORD: "/users/password",
  USER_CONFIRMATION: "/users/confirmation",
  PATHOLOGY_DETAILS: "/pathology_details",
  PATHOLOGIES: "/pathologies",
  BODY_PARTS: "/body_parts",
  PATHOLOGY_BLOBS: "/pathology_blobs",
  PROFESSIONALS: "/professionals",
  DIAGNOSTICS: "/diagnostics",
  BALANCE_TRANSACTIONS: "/balance_transactions",
  USER_REQUESTS: "/user_requests",
  QUOTES: "/quotes",
  ACTIVE_STORAGE_REDIRECT: "/rails/active_storage/blobs/redirect",
  ACTIVE_STORAGE_DIRECT_UPLOADS: "/rails/active_storage/direct_uploads",
}

export const API_ROUTES = {
  [API_CLIENTS_MAP.NEMO]: { ...API_NEMO_ROUTES },
} as const;
