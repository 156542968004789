import styled from 'styled-components';
import {breakpoint} from 'styled-components-breakpoint';

const Form = styled.form`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;

  > button {
    width: 100%;
  }
  
  > :last-child {
    margin-top: 32px;
  }
  
  ${breakpoint('sm' as any)`
    max-width: 480px;
    
    > button {
      max-width: 320px;
    }
  `}
`;

export default Form;