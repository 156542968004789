import DrawActionButton from '../../_ui/draw-action-button';
import * as React from 'react';
import styled from 'styled-components';
import {ReactComponent as FrontIcon} from '../../../_icons/front.svg';
import {ReactComponent as BackIcon} from '../../../_icons/back.svg';
import {ReactComponent as HeadIcon} from '../../../_icons/head.svg';
import {ReactComponent as FootIcon} from '../../../_icons/foot.svg';
import {useBodyParts} from '../../../_hooks/body-part/use-body-parts';
import {useEffect} from 'react';

const bodyPartsIcons = {
  front: FrontIcon,
  back: BackIcon,
  face: HeadIcon,
  foot: FootIcon,
};

const BodyPartContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);
  border: 1px solid var(--Border-x-light, rgba(0, 0, 0, 0.03));
  background: var(--Neutral-Grey-100, #FFF);
  box-sizing: border-box;
  align-items: center;
  gap: 4px;
  padding: 4px;
`;

const BodyPartToolBox = ({selectedBodyPart, setSelectedBodyPart}) => {
  const {bodyParts} = useBodyParts();

  useEffect(() => {
    if (!selectedBodyPart) {
      setSelectedBodyPart(bodyParts?.list?.[0]);
    }
  }, [selectedBodyPart, bodyParts]);

  return (
    <BodyPartContainer>
      {bodyParts?.list?.map((bodyPart, index) => {
        const Icon = bodyPartsIcons[bodyPart.label];
        return (
          <DrawActionButton
            key={index}
            onClick={() => setSelectedBodyPart(bodyPart)}
            style={{
              background: selectedBodyPart?.id === bodyPart.id ? '#ADE82F' : undefined,
            }}
          >
            <Icon/>
          </DrawActionButton>
        );
      })}
    </BodyPartContainer>
  );
};

export default BodyPartToolBox;
