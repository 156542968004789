import styled from 'styled-components';

const DrawActionButton = styled.button`
  border: none;
  border-radius: 12px;

  background: var(--Colors-Primary-Eerie-Black-60, #DCDCD9);

  /* Std-blur */
  backdrop-filter: blur(25px);

  width: 44px;
  min-width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
`;

export default DrawActionButton;