import {PageContainer} from '../../_ui/page';
import {Link, useParams} from 'react-router-dom';
import * as React from 'react';
import styled from 'styled-components';
import {breakpoint} from 'styled-components-breakpoint';
import {useUpdateUserRequest, useUserRequest} from '../../../_hooks/user-request/use-user-requests';
import {ROUTES} from '../../../router/routes';
import Modal from '../../_ui/modal';
import {useState} from 'react';
import Button from '../../_ui/button';
import {ReactComponent as NavigateBeforeIcon} from '../../../_icons/navigate_before.svg';
import {APIBalanceTransaction} from '../../../_services/api/_helpers/api-types';
import {useFormik} from 'formik';
import {Form, TextField} from '../../_ui/form';
import {userRequestRefuseFormValidationSchema} from './validation-schema';
import Caption from '../../_ui/caption';
import {UserRequestAcceptedCaption, UserRoleCaption} from '../_commons/captions';
import {useTranslation} from 'react-i18next';

const Information = ({Label, children}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flex: 1,
      gap: 8,
    }}
  >
    <div
      style={{
        display: 'flex',
        fontWeight: 600,
        flex: 1,
        width: 150,
        minWidth: 150,
        textAlign: 'left',
      }}
    >
      {Label}
    </div>
    <div
      style={{
        display: 'flex',
        flex: 1,
        minWidth: 180,
      }}
    >
      {children}
    </div>
  </div>
);

const InformationContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;


  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    ${breakpoint('md' as any)`
      width: 45%;
    `}
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--XL, 32px);
  background: var(--Colors-Neutral-white, #FFF);
  padding: 32px 44px 36px 44px;
  gap: 24px;
  //flex-shrink: 0;
  box-shadow: 0px 4px 250px 0px rgba(0, 0, 0, 0.25);
  margin: 20px;
  //height: 80vh;
`;

const UserRequestRefuseForm = (
  {initialValues = {}, onSuccess} :
    {initialValues?: Partial<APIBalanceTransaction>, onSuccess?: any}
) => {
  const {updateUserRequest, isLoading} = useUpdateUserRequest();
  const {errors, handleChange, handleSubmit, values, isValid, isSubmitting, touched, handleBlur, setSubmitting, resetForm} = useFormik({
    initialValues: {
      accepted: false,
      comment: '',
      ...initialValues,
    },
    onSubmit: async (formValues) => {
      await updateUserRequest(formValues);
      onSuccess?.();
      setSubmitting(false);
      resetForm();
    },
    validationSchema: userRequestRefuseFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <Form>
      <TextField
        type="text"
        name="comment"
        required
        placeholder={"Raison du refus"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.comment}
        hasError={touched.reason && !!errors.reason}
        // errorMessage={errors.reason}
      />
      <Button
        type="submit"
        disabled={!isValid || (isLoading || isSubmitting)}
        onClick={handleSubmit}
      >
        Refuser
      </Button>
    </Form>
  )
};

const UserRequestPage = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const {userRequest, isLoading, getUserRequest} = useUserRequest({id});
  const {user} = userRequest || {};
  const [documentsModalIsOpen, setDocumentsModalIsOpen] = useState<boolean>(false);
  const [refuseModalIsOpen, setRefuseModalIsOpen] = useState<boolean>(false);
  const {updateUserRequest, isLoading: updateUserRequestIsLoading} = useUpdateUserRequest();

  const acceptUserRequest = async () => {
    await updateUserRequest({id, accepted: true});
    setTimeout(getUserRequest, 500);
  };

  return (
    <PageContainer style={{display: 'flex', flexDirection: 'column', gap: 20}}>
      {!isLoading && (
        <>
          <h1>Demande Étudiante de {user.first_name} {user.last_name}</h1>
          <InformationContainer>
            <div>
              <Information
                Label={"ID"}
              >
                <Caption copy>
                  {userRequest.id}
                </Caption>
              </Information>
              <Information
                Label={"User ID"}
              >
                <Link to={ROUTES.USERS(user.id)}>{user.id}</Link>
              </Information>
              <Information
                Label={"Prénom"}
              >
                {user.first_name}
              </Information>
              <Information
                Label={"Nom"}
              >
                {user.last_name}
              </Information>
              <Information
                Label={"Email"}
              >
                <Caption copy>
                  {user.email}
                </Caption>
              </Information>
              <Information
                Label={"Role"}
              >
                <UserRoleCaption
                  className={user.role}
                >
                  {t(`enums.user.role.${user.role}`)}
                </UserRoleCaption>
              </Information>
            </div>
            <div>
              <Information
                Label={"Statut demande"}
              >
                <UserRequestAcceptedCaption
                  className={userRequest.accepted?.toString()}
                >
                  {t([`enums.user_request.accepted.${userRequest.accepted?.toString()}`, 'enums.user_request.accepted.null'])}
                </UserRequestAcceptedCaption>
              </Information>
              <Information
                Label={"Date Demande"}
              >
                {
                  new Date(userRequest.created_at).toLocaleDateString('fr-FR', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
                }
              </Information>
              <Information
                Label={"École"}
              >
                {userRequest.school_name}
              </Information>
              <Information
                Label={"Pays"}
              >
                {userRequest.school_country}
              </Information>
              <Information
                Label={"Année"}
              >
                {userRequest.school_year}
              </Information>
              <Information
                Label={"Date traitement demande"}
              >
                {
                  userRequest.answered_at && new Date(userRequest.answered_at).toLocaleDateString('fr-FR', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
                }
              </Information>
              <Information
                Label={"Commentaire"}
              >
                {userRequest.comment}
              </Information>
            </div>
          </InformationContainer>
        </>
      )}
      <div>
        <Button
          onClick={() => setDocumentsModalIsOpen(true)}
          style={{
            margin: '0 auto',
          }}
        >
          Voir le(s) justificatif(s)
        </Button>
      </div>
      {userRequest?.accepted === null && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: 0,
            gap: 16,
          }}
        >
          <Button
            $display="accent"
            onClick={acceptUserRequest}
            disabled={updateUserRequestIsLoading}
          >
            Valider
          </Button>
          <Button
            onClick={() => setRefuseModalIsOpen(true)}
            style={{backgroundColor: '#FF3B30', borderColor: '#FF3B30'}}
          >
            Refuser
          </Button>
        </div>
      )}
      {documentsModalIsOpen && (
        /* @ts-ignore */
        <Modal
          fullScreen
          isOpen={documentsModalIsOpen}
          onRequestClose={() => setDocumentsModalIsOpen(false)}
          overlay={true}
        >
          <ModalContent>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                position: 'relative',
              }}
            >
              <NavigateBeforeIcon
                width={48}
                height={48}
                onClick={() => setDocumentsModalIsOpen(false)}
                style={{position: 'absolute', left: 0}}
              />
              <h2>Justificatif(s)</h2>
            </div>
            {userRequest?.attachments?.map(attachment => (
              <Link
                key={attachment.id}
                to={attachment.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <embed
                  type={attachment.content_type}
                  src={attachment.url}
                  style={{
                    background: 'white',
                    objectFit: 'contain',
                    width: 800,
                    height: 500,
                  }}
                />
              </Link>
            ))}
          </ModalContent>
        </Modal>
      )}
      {refuseModalIsOpen && (
        /* @ts-ignore */
        <Modal
          isOpen={refuseModalIsOpen}
          onRequestClose={() => setRefuseModalIsOpen(false)}
          overlay={true}
        >
          <ModalContent>
            <h2>Refuser la demande</h2>
            <UserRequestRefuseForm
              initialValues={{id: +id}}
              onSuccess={() => {
                setTimeout(getUserRequest, 500);
                setRefuseModalIsOpen(false);
              }}
            />
          </ModalContent>
        </Modal>
      )}
    </PageContainer>
  )
};

export default UserRequestPage;