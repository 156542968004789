import * as yup from "yup";

const userFormValidationSchema = yup.object().shape({
  first_name: yup.string().min(1).required("errors.form.required.field"),
  last_name: yup.string().min(1).required("errors.form.required.field"),
  email: yup.string().email().min(1).required("errors.form.required.field"),
  password: yup.string(),
  birth_date: yup.string(),
});

export {
  userFormValidationSchema,
};
