import {useMutation, useQuery, useQueryClient} from "react-query";
import {QuoteAPIService} from "../../_services/api";
import {quotesKeys} from './queries';
import {APIListPayload, APIListResponse} from '../../_services/api/_helpers/api-list-types';
import {APIQuote} from '../../_services/api/_helpers/api-types';

export function useQuotes(payload?: APIListPayload) {
  const { refetch, isLoading, isError, error, data } = useQuery(
    [quotesKeys.quotes, payload],
    (formData : object) => QuoteAPIService.getQuotes(payload),
    {
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    getQuotes: refetch,
    quotes: data,
    isLoading,
    isError,
    error,
  };
}

export function useQuote({id}) {
  const queryClient = useQueryClient();
  const { refetch, isLoading, isError, error, data } = useQuery(
    quotesKeys.quotes,
    (formData : object) => QuoteAPIService.getQuote(id),
    {
      onSuccess: async (data) => {
        queryClient.setQueryData(
          quotesKeys.quotes,
          (quotes: APIListResponse<APIQuote>) => (
            {
              ...quotes,
              //@ts-ignore
              list: [...(quotes?.list || []).map(c => c.id !== data?.id ? c : data)]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
      cacheTime: 250,
      refetchOnMount: "always",
    }
  );
  return {
    getQuote: refetch,
    quote: data,
    isLoading,
    isError,
    error,
  };
}

export function useCreateQuote() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    quotesKeys.quotes,
    (formData : object) => QuoteAPIService.createQuote(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          quotesKeys.quotes,
          (quotes: APIListResponse<APIQuote>) => (
            {
              ...quotes,
              list: [...quotes?.list, data]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    createQuote: mutate,
    isLoading,
    isError,
    error,
  };
}

export function useUpdateQuote() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    quotesKeys.quotes,
    (formData : object ) => QuoteAPIService.updateQuote(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          quotesKeys.quotes,
          (quotes: APIListResponse<APIQuote>) => (
            {
              ...quotes,
              //@ts-ignore
              list: [...(quotes?.list || []).map(c => c.id !== variables?.id ? c : data)]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    updateQuote: mutate,
    isLoading,
    isError,
    error,
  };
}

export function useDeleteQuote() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess, data, isError, error } = useMutation(
    quotesKeys.quotes,
    (id: number) => QuoteAPIService.deleteQuote(id),
    {
      onSuccess: async (data, variables) => {
        await queryClient.setQueryData(
          quotesKeys.quotes,
          (quotes: APIListResponse<APIQuote>) => ({
            ...quotes,
            list: [...quotes?.list?.filter(c => c.id !== variables)]
          })
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    deleteQuote: mutate,
    isLoading,
    isSuccess,
    isError,
    response: data,
    error,
  };
}