import * as yup from "yup";

const balanceTransactionFormValidationSchema = yup.object().shape({
  quantity: yup.number().notOneOf([0]).required("errors.form.required.field"),
  reason: yup.string().required("errors.form.required.field"),
  unit: yup.string().oneOf(['coin']).required("errors.form.required.field"),
  category: yup.string().oneOf(['regular', 'referral']).required("errors.form.required.field"),
});

export {
  balanceTransactionFormValidationSchema,
};