import {useMutation, useQuery, useQueryClient} from "react-query";
import {PathologyBlobAPIService} from "../../_services/api";
import {pathologyBlobsKeys} from './queries';
import {APIListPayload, APIListResponse} from '../../_services/api/_helpers/api-list-types';
import {APIPathologyBlob} from '../../_services/api/_helpers/api-types';

export function usePathologyBlobs(payload?: APIListPayload) {
  const { refetch, isLoading, isError, error, data } = useQuery(
    [pathologyBlobsKeys.pathologyBlobs, payload],
    (formData : object) => PathologyBlobAPIService.getPathologyBlobs(payload),
    {
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    getPathologyBlobs: refetch,
    pathologyBlobs: data,
    isLoading,
    isError,
    error,
  };
}

export function usePathologyBlob({id}) {
  const queryClient = useQueryClient();
  const { refetch, isLoading, isError, error, data } = useQuery(
    pathologyBlobsKeys.pathologyBlobs,
    (formData : object) => PathologyBlobAPIService.getPathologyBlob(id),
    {
      onSuccess: async (data) => {
        queryClient.setQueryData(
          pathologyBlobsKeys.pathologyBlobs,
          (pathologyBlobs: APIListResponse<APIPathologyBlob>) => (
            {
              ...pathologyBlobs,
              //@ts-ignore
              list: [...(pathologyBlobs?.list || []).map(c => c.id !== data?.id ? c : data)]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
      cacheTime: 250,
      refetchOnMount: "always",
    }
  );
  return {
    getPathologyBlob: refetch,
    pathologyBlob: data,
    isLoading,
    isError,
    error,
  };
}

export function useCreatePathologyBlob() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    pathologyBlobsKeys.pathologyBlobs,
    (formData : object) => PathologyBlobAPIService.createPathologyBlob(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          pathologyBlobsKeys.pathologyBlobs,
          (pathologyBlobs: APIListResponse<APIPathologyBlob>) => (
            {
              ...pathologyBlobs,
              list: [...(pathologyBlobs?.list || []), data]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    createPathologyBlob: mutate,
    isLoading,
    isError,
    error,
  };
}

export function useUpdatePathologyBlob() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    pathologyBlobsKeys.pathologyBlobs,
    (formData : object ) => PathologyBlobAPIService.updatePathologyBlob(formData),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          pathologyBlobsKeys.pathologyBlobs,
          (pathologyBlobs: APIListResponse<APIPathologyBlob>) => (
            {
              ...pathologyBlobs,
              //@ts-ignore
              list: [...(pathologyBlobs?.list || []).map(c => c.id !== variables?.id ? c : data)]
            }
          )
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    updatePathologyBlob: mutate,
    isLoading,
    isError,
    error,
  };
}

export function useDeletePathologyBlob() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess, data, isError, error } = useMutation(
    pathologyBlobsKeys.pathologyBlobs,
    (id: number) => PathologyBlobAPIService.deletePathologyBlob(id),
    {
      onSuccess: async (data, variables) => {
        await queryClient.setQueryData(
          pathologyBlobsKeys.pathologyBlobs,
          (pathologyBlobs: APIListResponse<APIPathologyBlob>) => ({
            ...pathologyBlobs,
            list: [...pathologyBlobs?.list?.filter(c => c.id !== variables)]
          })
        );
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    deletePathologyBlob: mutate,
    isLoading,
    isSuccess,
    isError,
    response: data,
    error,
  };
}