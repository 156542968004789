import {APIPathologyBlob} from '../../../_services/api/_helpers/api-types';
import {
  useCreatePathologyBlob,
  useDeletePathologyBlob,
  useUpdatePathologyBlob
} from '../../../_hooks/pathology-blobs/use-pathology-blobs';
import {ComponentProps, useEffect, useState} from 'react';
import {dragElement} from '../../../_services/utils/drag-element';
import BrushSizeSelect from '../../_ui/brush-size-select';
import DrawActionButton from '../../_ui/draw-action-button';
import styled from 'styled-components';
import {ReactComponent as UndoIcon} from '../../../_icons/undo.svg';
import {ReactComponent as RedoIcon} from '../../../_icons/redo.svg';
import {ReactComponent as TrashIcon} from '../../../_icons/trash.svg';
import {ReactComponent as SaveIcon} from '../../../_icons/save.svg';
import {ReactComponent as SearchIcon} from '../../../_icons/search.svg';
import {breakpoint} from 'styled-components-breakpoint';

type FlexToolBoxDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse';

type FlexToolBoxStyledDirection = 'row' | 'column';

type flexDirectionsSelectAssociationsInterface = {
  readonly [direction in FlexToolBoxDirection]: FlexToolBoxDirection;
};

type flexDirectionsAssociationsInterface = {
  readonly [direction in FlexToolBoxDirection]: FlexToolBoxStyledDirection;
};

type FlexToolBoxProps = ComponentProps<'div'> & {
  getActions: any;
  pathologyBlob?: APIPathologyBlob;
  readonly?: boolean;
  direction?: FlexToolBoxDirection;
  draggable?: boolean;
  onRefresh?: () => any;
  onSearch?: (event) => any;
};

const FlexToolBoxStyled = styled.div<{$direction: FlexToolBoxStyledDirection;}>`
  display: flex;
  flex-direction: ${props => props.$direction};
  border-radius: 15px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);
  border: 1px solid var(--Border-x-light, rgba(0, 0, 0, 0.03));
  background: var(--Neutral-Grey-100, #FFF);
  box-sizing: border-box;

  > div {
    flex-direction: ${props => props.$direction};
  }
  
  div[id$="-header"] {
    cursor: move;
    background-color: #ADE82F;
    width: ${props => props.$direction === 'row' ? '15px' : '100%'};
    height: ${props => props.$direction === 'row' ? undefined : '15px'};
    border-radius: ${props => props.$direction === 'row' ? '12px 0 0 12px' : '12px 12px 0 0'};
    display: none;
    
    ${breakpoint('md' as any)`
      display: block;
    `}
  }
`;

const INLINE_TOOLBOX_ID = 'nemo-toolbox';

const flexDirectionsSelectAssociations : flexDirectionsSelectAssociationsInterface = {
  'row': 'column',
  'column': 'row',
  'row-reverse': 'column-reverse',
  'column-reverse': 'row-reverse',
};

const flexDirectionsAssociations : flexDirectionsAssociationsInterface = {
  'row': 'row',
  'column': 'column',
  'row-reverse': 'row',
  'column-reverse': 'column',
};

const FlexToolBox = (
  {
    getActions,
    pathologyBlob,
    readonly = true,
    direction = 'row',
    draggable = false,
    onRefresh = () => {},
    onSearch,
    ...rest
  } : FlexToolBoxProps
) => {
  const {createPathologyBlob} = useCreatePathologyBlob();
  const {updatePathologyBlob} = useUpdatePathologyBlob();
  const {deletePathologyBlob} = useDeletePathologyBlob();

  const [brushSize, setBrushSize] = useState<number>(40);

  useEffect(() => {
    dragElement(document.getElementById(INLINE_TOOLBOX_ID));
  }, []);

  useEffect(() => {
    getActions()?.setBrushWidth?.(brushSize);
  }, [brushSize]);

  const handleSave = async () => {
    const res = await getActions()?.processImage();

    if (new RegExp('[1-9]').test(res.binary_area)) {
      const payload = {
        ...pathologyBlob,
        ...res,
      };

      if (pathologyBlob?.id) {
        await updatePathologyBlob(payload);
      } else {
        await createPathologyBlob(payload);
      }
      onRefresh();
    } else {
      if (pathologyBlob?.id) {
        await deletePathologyBlob(pathologyBlob?.id);
      }
    }
  }

  return (
    <FlexToolBoxStyled
      id={INLINE_TOOLBOX_ID}
      $direction={flexDirectionsAssociations[direction]}
      {...rest}
    >
      {draggable && <div id="nemo-toolbox-header"/>}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          padding: 4,
          boxSizing: 'border-box',
        }}
      >
        <DrawActionButton onClick={() => getActions().undo()}>
          <UndoIcon/>
        </DrawActionButton>
        <DrawActionButton onClick={() => getActions().redo()}>
          <RedoIcon/>
        </DrawActionButton>
        <DrawActionButton
          onClick={() => {
            if (window.confirm("Voulez-vous vraiment supprimer cet élément?")) {
              getActions().clear();
            }
          }}
        >
          <TrashIcon/>
        </DrawActionButton>
        <BrushSizeSelect
          direction={flexDirectionsSelectAssociations[direction]}
          value={brushSize}
          onChange={setBrushSize}
          optionsPosition="absolute"
        />
        {!readonly && (
          <DrawActionButton onClick={handleSave}>
            <SaveIcon/>
          </DrawActionButton>
        )}
        {onSearch && (
          <DrawActionButton onClick={onSearch}>
            <SearchIcon/>
          </DrawActionButton>
        )}
      </div>
    </FlexToolBoxStyled>
  );
};

export default FlexToolBox;