import styled from 'styled-components';
import Button from '../../../_ui/button';
import {ReactComponent as CameraIcon} from '../../../../_icons/camera.svg';
import Monogram from '../../../_ui/monogram';
import {useAuthContext} from '../../../../_contexts/auth';
import {DirectUploadProvider} from 'react-activestorage-provider';
import {useEffect, useRef, useState} from 'react';
import {useDeleteUser, useUpdateUser} from '../../../../_hooks/user/use-users';
import {UserAPIService} from '../../../../_services/api';
import {API_NEMO_ROUTES} from '../../../../_services/api/config/config';
import {REACT_APP_API_URL} from '../../../../_config/env';
import {useNavigate} from 'react-router-dom';
import {Form, TextField} from '../../../_ui/form';
import {useFormik} from 'formik';
import {userFormValidationSchema} from './validation-schema';
import {APIUser} from '../../../../_services/api/auth/types';
import Caption from '../../../_ui/caption';
import {ROUTES} from '../../../../router/routes';
import * as React from 'react';
import {logout} from '../../../../_services/api/auth';
import MascotModal from '../../../_ui/mascot-modal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CameraCaption = styled(CameraIcon)`
  position: absolute;
  right: 5px;
  bottom: 3px;
  width: 28px;
  height: 28px;
  background-color: var(--Background-Primary, #FFF);
  border-radius: 50%;
  cursor: pointer;
`;

export const UploadProvider = ({onSuccess, multiple=false, name, id, children, inputProps={}, maxFileSize = 50*1024*1024}) => {
  const [internalValue, setInternalValue] = useState();
  const [originalBlobUrl, setOriginalBlobUrl] = useState();
  const _internalName = `__internal_${name}_label${id || ''}`;
  const inputRef = useRef();

  useEffect(() => {
    // @ts-ignore
    const files = inputRef?.current?.files || [];
    // @ts-ignore
    if (internalValue && files[0]?.name) {
      const blobUrls = Array.from(files).map((file: File, index: number) => ({
        signed_id: internalValue[index],
        // @ts-ignore
        filename: file?.name,
        // @ts-ignore
        url: `${REACT_APP_API_URL}${API_NEMO_ROUTES.ACTIVE_STORAGE_REDIRECT}/${internalValue[index]}/${file?.name}`,
      }));
      onSuccess(multiple ? blobUrls : blobUrls[0]);
    }
  }, [internalValue]);

  const handleSuccess = (response) => {
    setInternalValue(response);
  }

  return (
    <DirectUploadProvider
      directUploadsPath={`${REACT_APP_API_URL}${API_NEMO_ROUTES.ACTIVE_STORAGE_DIRECT_UPLOADS}`}
      onSuccess={handleSuccess}
      multiple={multiple}
      render={({ handleUpload, uploads, ready }) => {
        // filesRef.current = uploads;

        return (
          <div>
            <input
              ref={inputRef}
              id={_internalName}
              name={_internalName}
              type="file"
              disabled={!ready}
              onChange={e => {
                const tooBigFile = [...new Array(e.currentTarget.files.length)].map((_, i) => e.currentTarget.files.item(i)).find(file => file.size > maxFileSize);
                if (tooBigFile) {
                  alert(`La taille maximum des fichier est de ${maxFileSize} octets. Le fichier suivant est trop lourd : ${tooBigFile.name}`);
                  e.currentTarget.value = "";
                } else {
                  handleUpload(e.currentTarget.files);
                }
              }}
              multiple={multiple}
              // value={internalValue}
              {...inputProps}
              style={{display: 'none'}}
            />
            <label htmlFor={_internalName}>
              {children}
            </label>
            {/*{uploads.map(upload => {*/}
            {/*  switch (upload.state) {*/}
            {/*    case 'waiting':*/}
            {/*      return <p key={upload.id}>Waiting to upload {upload.file.name}</p>*/}
            {/*    case 'uploading':*/}
            {/*      return (*/}
            {/*        <p key={upload.id}>*/}
            {/*          Uploading {upload.file.name}: {upload.progress}%*/}
            {/*        </p>*/}
            {/*      )*/}
            {/*    case 'error':*/}
            {/*      return (*/}
            {/*        <p key={upload.id}>*/}
            {/*          Error uploading {upload.file.name}: {upload.error}*/}
            {/*        </p>*/}
            {/*      )*/}
            {/*    case 'finished':*/}
            {/*      return (*/}
            {/*        <p key={upload.id}>Finished uploading {upload.file.name}</p>*/}
            {/*      )*/}
            {/*  }*/}
            {/*})}*/}
          </div>
        );
      }}
    />
  );
};

const Avatar = () => {
  const {updateUser} = useUpdateUser();
  const {user} = useAuthContext();

  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
    setAvatarUrl(user.avatar?.url || '');
  }, [user.avatar?.url]);

  const handleSuccess = async (response) => {
    if (response.signed_id) {
      // @ts-ignore
      await updateUser({id: user.id, avatar: {signed_id: response.signed_id}});
    }
    setAvatarUrl(response.url);
  };

  return (
    <div
      style={{
        margin: '0 20px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <UploadProvider
        id={"avatar"}
        name={"avatar"}
        onSuccess={handleSuccess}
        inputProps={{
          accept: "image/jpeg,image/png"
        }}
      >
        <Monogram
          style={{
            position: 'relative',
            border: 'none',
            fontSize: '4rem',
            width: 120,
            height: 120,
            cursor: 'pointer',
          }}
          $backgroundImageUrl={avatarUrl}
        >
          {avatarUrl ? null : user.first_name.charAt(0)}
          <CameraCaption/>
        </Monogram>
      </UploadProvider>
    </div>
  );
}

const UserForm = (
  {initialValues, onSuccess}:
    {initialValues: APIUser, onSuccess: Function}
) => {
  const {
    errors,
    handleChange,
    handleSubmit,
    values,
    isValid,
    touched,
    handleBlur,
    isSubmitting,
    setSubmitting,
    resetForm,
  } = useFormik({
    initialValues: {
      ...initialValues,
      password: '',
    },
    onSubmit: (formValues) => {
      const {password, ...userInfos} = formValues;
      if (!!password) {
        // @ts-ignore
        userInfos.password = password;
      }
      UserAPIService.updateUser(userInfos).then((user) => {
        setSubmitting(false);
        resetForm();
        onSuccess?.();
      });
    },
    validationSchema: userFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <Form style={{padding: '12px 20px 12px 20px'}}>
      <TextField
        name="first_name"
        required
        label={"Prénom"}
        placeholder={"Prénom"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.first_name}
        hasError={touched.first_name && !!errors.first_name}
        errorMessage={errors.first_name}
        smallVerticalPadding
      />
      <TextField
        name="last_name"
        required
        label={"Nom"}
        placeholder={"Nom"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.last_name}
        hasError={touched.last_name && !!errors.last_name}
        errorMessage={errors.last_name}
        smallVerticalPadding
      />
      <TextField
        type="email"
        name="email"
        required
        label={"Email"}
        placeholder={"Email"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        hasError={touched.email && !!errors.email}
        errorMessage={errors.email}
        smallVerticalPadding
      />
      <TextField
        type="password"
        name="password"
        label={"Mot de passe"}
        placeholder={"********"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        hasError={touched.password && !!errors.password}
        errorMessage={errors.password}
        smallVerticalPadding
      />
      <TextField
        type="date"
        name="birth_date"
        required
        label={"Date de naissance"}
        placeholder={"13/01/1989"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.birth_date || ''}
        hasError={touched.birth_date && !!errors.birth_date}
        errorMessage={errors.birth_date}
        smallVerticalPadding
      />
      <Button
        disabled={!isValid || isSubmitting}
        onClick={handleSubmit}
      >
        Sauvegarder
      </Button>
    </Form>
  );
};

const UserPage = () => {
  const {user, setUser} = useAuthContext();
  const [openModal, setOpenModal] = useState<string | null>(null)
  const {deleteUser, isSuccess: deleteUserIsSuccess} = useDeleteUser();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const userData = await UserAPIService.getUser();
      if (JSON.stringify(userData) !== JSON.stringify(user)){
        setUser(userData);
      }
    })();
  }, []);

  useEffect(() => {
    if (deleteUserIsSuccess) {
      (async () => await logout(false))();
    }
  }, [deleteUserIsSuccess]);

  const deleteMyAccount = async () => {
    await deleteUser(user.id);
  };

  return (
    <Container>
      <h1 style={{flex: 1, margin: '20px', textAlign: 'center'}}>Mes informations</h1>
      <Avatar/>
      <div
        style={{
          padding: '0px 20px 0px 20px',
          display: 'flex',
          gap: 12,
          justifyContent: 'center',
          fontWeight: 'bold',
        }}
      >
        Mon code de parrainage
        <Caption
          copy
          copyContent={`${window.location.origin}${ROUTES.SIGNUP()}?sponsor_code=${user.sponsorship_code}`}
        >
          {user.sponsorship_code}
        </Caption>
      </div>
      <UserForm
        initialValues={user}
        onSuccess={() => navigate(-1)}
      />
      <span
        onClick={() => setOpenModal('deleteAccountModal')}
      >
        Supprimer mon compte
      </span>
      <MascotModal
        isOpen={!!openModal}
        onClose={() => setOpenModal(null)}
        onContinue={deleteMyAccount}
        translationKey={`user.${openModal}`}
      />
    </Container>
  );
};

export default UserPage;