import styled from 'styled-components';
import Button from '../../../_ui/button';
import {ReactComponent as PlayArrowIcon} from '../../../../_icons/play_arrow.svg';
import {ReactComponent as ConditionsIcon} from '../../../../_icons/conditions.svg';
import {ReactComponent as Diversity3Icon} from '../../../../_icons/diversity_3.svg';
import {ReactComponent as HotelClassIcon} from '../../../../_icons/hotel_class.svg';
import {ReactComponent as PokerChipIcon} from '../../../../_icons/poker_chip.svg';
import {ReactComponent as CameraIcon} from '../../../../_icons/camera.svg';
import Monogram from '../../../_ui/monogram';
import {useAuthContext} from '../../../../_contexts/auth';
import {DirectUploadProvider} from 'react-activestorage-provider';
import {useEffect, useRef, useState} from 'react';
import {useUpdateUser} from '../../../../_hooks/user/use-users';
import {UserAPIService} from '../../../../_services/api';
import {API_NEMO_ROUTES} from '../../../../_services/api/config/config';
import {REACT_APP_API_URL} from '../../../../_config/env';
import {ReactComponent as PersonIcon} from '../../../../_icons/person.svg';
import {ReactComponent as NemoIcon} from '../../../../_icons/nemo.svg';
import {ReactComponent as DiversityIcon} from '../../../../_icons/diversity.svg';
import {ReactComponent as CreditCardIcon} from '../../../../_icons/credit_card.svg';
import {ReactComponent as QuizIcon} from '../../../../_icons/quiz.svg';
import {ReactComponent as ContactSupportIcon} from '../../../../_icons/contact_support.svg';
import {ReactComponent as InfoIcon} from '../../../../_icons/info.svg';
import {ReactComponent as BugReportIcon} from '../../../../_icons/bug_report.svg';
import {ReactComponent as StarIcon} from '../../../../_icons/star.svg';
import {ReactComponent as LogoutIcon} from '../../../../_icons/logout.svg';
import {logout} from '../../../../_services/api/auth';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../../../../router/routes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CardsContainer = styled.div`
  margin: 0 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 16px;
`;

const CardStyled = styled.div`
  display: flex;
  padding: var(--M, 16px) var(--XL, 24px) var(--XXL, 32px) var(--XL, 24px);
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  border-radius: var(--L, 24px);
  &:nth-of-type(1) {
    background: var(--Colors-Tertiary-Teal-highlight, #F3FCF7);
  }
  &:nth-of-type(2) {
    background: var(--Colors-Tertiary-Azure-highlight, #F0FAFF);
  }
  &:nth-of-type(3) {
    background: var(--Colors-Tertiary-Maize-highlight, #FFFCE5);
  }
  &:nth-of-type(4) {
    background: var(--Colors-Tertiary-Peach-highlight, #FFF7F5);
  }
  
  > div {
    color: var(--Text-primary, #1D1D1B);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Noto Sans";
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px; /* 92.857% */
    letter-spacing: -0.4px;
  }
  
  > span {
    color: var(--Text-primary-light, #636360);
    font-feature-settings: 'clig' off, 'liga' off;
    /* Mobile/Caption1 */
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.2px;
    text-align: left;
    max-width: 60%;
  }
`;

const CameraCaption = styled(CameraIcon)`
  position: absolute;
  right: 5px;
  bottom: 3px;
  width: 28px;
  height: 28px;
  background-color: var(--Background-Primary, #FFF);
  border-radius: 50%;
  cursor: pointer;
`;

export const UploadProvider = ({onSuccess, multiple=false, name, id, children, inputProps={}, maxFileSize = 50*1024*1024}) => {
  const [internalValue, setInternalValue] = useState();
  const [originalBlobUrl, setOriginalBlobUrl] = useState();
  const _internalName = `__internal_${name}_label${id || ''}`;
  const inputRef = useRef();

  useEffect(() => {
    // @ts-ignore
    const files = inputRef?.current?.files || [];
    // @ts-ignore
    if (internalValue && files[0]?.name) {
      const blobUrls = Array.from(files).map((file: File, index: number) => ({
        signed_id: internalValue[index],
        // @ts-ignore
        filename: file?.name,
        // @ts-ignore
        url: `${REACT_APP_API_URL}${API_NEMO_ROUTES.ACTIVE_STORAGE_REDIRECT}/${internalValue[index]}/${file?.name}`,
      }));
      onSuccess(multiple ? blobUrls : blobUrls[0]);
    }
  }, [internalValue]);

  const handleSuccess = (response) => {
    setInternalValue(response);
  }

  return (
    <DirectUploadProvider
      directUploadsPath={`${REACT_APP_API_URL}${API_NEMO_ROUTES.ACTIVE_STORAGE_DIRECT_UPLOADS}`}
      onSuccess={handleSuccess}
      multiple={multiple}
      render={({ handleUpload, uploads, ready }) => {
        // filesRef.current = uploads;

        return (
          <div>
            <input
              ref={inputRef}
              id={_internalName}
              name={_internalName}
              type="file"
              disabled={!ready}
              onChange={e => {
                const tooBigFile = [...new Array(e.currentTarget.files.length)].map((_, i) => e.currentTarget.files.item(i)).find(file => file.size > maxFileSize);
                if (tooBigFile) {
                  alert(`La taille maximum des fichier est de ${maxFileSize} octets. Le fichier suivant est trop lourd : ${tooBigFile.name}`);
                  e.currentTarget.value = "";
                } else {
                  handleUpload(e.currentTarget.files);
                }
              }}
              multiple={multiple}
              // value={internalValue}
              {...inputProps}
              style={{display: 'none'}}
            />
            <label htmlFor={_internalName}>
              {children}
            </label>
            {/*{uploads.map(upload => {*/}
            {/*  switch (upload.state) {*/}
            {/*    case 'waiting':*/}
            {/*      return <p key={upload.id}>Waiting to upload {upload.file.name}</p>*/}
            {/*    case 'uploading':*/}
            {/*      return (*/}
            {/*        <p key={upload.id}>*/}
            {/*          Uploading {upload.file.name}: {upload.progress}%*/}
            {/*        </p>*/}
            {/*      )*/}
            {/*    case 'error':*/}
            {/*      return (*/}
            {/*        <p key={upload.id}>*/}
            {/*          Error uploading {upload.file.name}: {upload.error}*/}
            {/*        </p>*/}
            {/*      )*/}
            {/*    case 'finished':*/}
            {/*      return (*/}
            {/*        <p key={upload.id}>Finished uploading {upload.file.name}</p>*/}
            {/*      )*/}
            {/*  }*/}
            {/*})}*/}
          </div>
        );
      }}
    />
  );
};

const Avatar = () => {
  const {updateUser} = useUpdateUser();
  const {user} = useAuthContext();

  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
    setAvatarUrl(user.avatar?.url || '');
  }, [user.avatar?.url]);

  const handleSuccess = async (response) => {
    if (response.signed_id) {
      // @ts-ignore
      await updateUser({id: user.id, avatar: {signed_id: response.signed_id}});
    }
    setAvatarUrl(response.url);
  };

  return (
    <div
      style={{
        margin: '0 20px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <UploadProvider
        id={"avatar"}
        name={"avatar"}
        onSuccess={handleSuccess}
        inputProps={{
          accept: "image/jpeg,image/png"
        }}
      >
        <Monogram
          style={{
            position: 'relative',
            border: 'none',
            fontSize: '4rem',
            width: 120,
            height: 120,
            cursor: 'pointer',
          }}
          $backgroundImageUrl={avatarUrl}
        >
          {avatarUrl ? null : user.first_name.charAt(0)}
          <CameraCaption/>
        </Monogram>
      </UploadProvider>
    </div>
  );
}

const SectionAccount = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  background: #FAFAFA;
  box-sizing: border-box;
  
  h2 {
    color: #99D41C;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.2px;
    align-self: stretch;
    margin: 0;
  }
  
  ul {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    
    li {
      display: flex;
      padding: 4px 0;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      color: #1D1D1B;
      text-align: center;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: -0.2px;
    }
  }
`;

const ProfilePage = () => {
  const {user, setUser} = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const userData = await UserAPIService.getUser();
      if (JSON.stringify(userData) !== JSON.stringify(user)){
        setUser(userData);
      }
    })();
  }, []);

  const creditAtFormatted = new Intl.DateTimeFormat('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(new Date(user.credit_at));

  const goToStripePortal = async () => {
    const response = await UserAPIService[user.plan === 'premium' ? 'getStripePortalSessionUser' : 'getStripeCheckoutSessionUser'](user.id, window.location.href);
    window.location.href = response.url;
  };

  const menuItems = [
    {
      title: 'Compte',
      items: [
        {
          Icon: PersonIcon,
          label: 'Mes informations',
          onClick: () => navigate(ROUTES.USERS(user.id)),
          visible: true,
        },
        {
          Icon: NemoIcon,
          label: user.plan === 'premium' ? 'Gérer mon abonnement' : 'Passer Premium',
          onClick: goToStripePortal,
          visible: true,
        },
        {
          Icon: DiversityIcon,
          label: 'Parrainage',
          onClick: () => {},
          visible: true,
        },
        {
          Icon: CreditCardIcon,
          label: 'Méthode de paiement',
          onClick: goToStripePortal,
          visible: true,
        },
        {
          Icon: CreditCardIcon,
          label: 'Mes Factures',
          onClick: goToStripePortal,
          visible: true,
        },
      ],
    },
    {
      title: 'Support & Contact',
      items: [
        {
          Icon: QuizIcon,
          label: 'FAQ',
          onClick: () => {},
          visible: true,
        },
        {
          Icon: ContactSupportIcon,
          label: 'Aide & Support',
          onClick: () => {},
          visible: true,
        },
        {
          Icon: InfoIcon,
          label: 'Informations Légales',
          onClick: () => {},
          visible: true,
        },
        {
          Icon: InfoIcon,
          label: 'Whats APP - (Aide en direct)',
          onClick: () => {},
          visible: true,
        },
      ],
    },
    {
      title: 'Actions',
      items: [
        {
          Icon: BugReportIcon,
          label: 'Signaler un bug',
          onClick: () => {},
          visible: true,
        },
        {
          Icon: StarIcon,
          label: 'Noter l’application',
          onClick: () => {},
          visible: true,
        },
        {
          Icon: LogoutIcon,
          label: 'Me déconnecter',
          onClick: async () => await logout(),
          visible: true,
        },
      ],
    },
  ];

  return (
    <Container>
      <h1 style={{flex: 1, margin: '20px', textAlign: 'center'}}>Mon profil</h1>
      <Avatar/>
      <span>Tu disposes de <b>{user.balance} crédits</b> jusqu'au <b>{creditAtFormatted}</b></span>
      <Button
        style={{flex: 1, margin: '0 20px'}}
        $display="accent"
        onClick={goToStripePortal}
      >
        {user.plan === 'premium' ? 'Gérer mon abonnement' : 'Passer Premium'} <PlayArrowIcon/>
      </Button>
      <CardsContainer>
        <CardStyled>
          <ConditionsIcon/>
          <div>{user.diagnostics_count}</div>
          <span>Diagnostics réalisés</span>
        </CardStyled>
        <CardStyled>
          <Diversity3Icon/>
          <div>{user.referrals_count}</div>
          <span>Membres parrainés</span>
        </CardStyled>
        <CardStyled>
          <HotelClassIcon/>
          <div>{user.closed_diagnostics_count}</div>
          <span>Diagnostics clôturés</span>
        </CardStyled>
        <CardStyled>
          <PokerChipIcon/>
          <div>{user.balance}</div>
          <span>Crédits gagnés</span>
        </CardStyled>
      </CardsContainer>
      <div
        style={{
          margin: '0 20px',
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
        }}
      >
        {
          menuItems.map((menuSection, index) => (
            <SectionAccount key={index}>
              <h2>{menuSection.title}</h2>
              <ul>
                {menuSection.items.filter(item => item.visible).map((item, indexItem) => (
                  <li
                    key={indexItem}
                    onClick={item.onClick}
                  >
                    <item.Icon width={40} height={40} />
                    {item.label}
                  </li>
                ))}
              </ul>
            </SectionAccount>
          ))
        }
      </div>
    </Container>
  );
};

export default ProfilePage;