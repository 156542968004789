import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`${
  css`
    // css Normalize
    // ${normalize};

    // global styles
    #root {
      --S: ${({theme}) => theme.spacings.small};
      --M: ${({theme}) => theme.spacings.medium};
      --L: ${({theme}) => theme.spacings.large};
      --XL: ${({theme}) => theme.spacings.xlarge};
      --XXL: ${({theme}) => theme.spacings.xxlarge};
      
      --Rounded: 99999px;
    }
    
    a {
      text-decoration: none;
      color: var(--Text-primary, #1D1D1B);
      font-feature-settings: 'clig' off, 'liga' off;
      /* Mobile/Body */
      font-family: "Noto Sans";
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 129.412% */
      letter-spacing: -0.2px;
    }
    
    p, span {
      color: var(--Text-primary-light, #636360);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      /* Mobile/Callout */
      font-family: "Noto Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px; /* 131.25% */
      letter-spacing: -0.2px;
    }
    
    p {
      font-size: 16px;
      line-height: 21px; /* 131.25% */
    }
    
    span {
      font-size: 13px;
      line-height: 18px; /* 138.462% */
    }
    
    h1 {
      color: var(--Primary-Black-P, var(--Colors-Primary-Eerie-Black-P, #1D1D1B));
      font-feature-settings: 'clig' off, 'liga' off;
      /* Mobile/Title1 */
      font-family: "Noto Sans";
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px; /* 121.429% */
      letter-spacing: -0.4px;
    }
  `
}`;

export { GlobalStyle };
export default GlobalStyle;
