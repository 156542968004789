import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationFR from "./fr/translations.json";

export const resources = {
  fr: {
    language: "Français",
    translation: {
      ...translationFR,
    },
  },
};

i18n.
  use(initReactI18next).
  init({
    compatibilityJSON: "v3",
    resources,
    lng: "fr",
    fallbackLng: "fr",
    supportedLngs: ['fr'],
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
  });

export function changeLanguage(lang: string) {
  i18n.changeLanguage(lang);
}