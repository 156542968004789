const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
  menuFull: 1110
};

const fonts = {
  serif: 'bookmania, serif',
  sans: '"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
};

const spacings = {
  small: '4px',
  medium: '8px',
  large: '16px',
  xlarge: '24px',
  xxlarge: '32px',
};

const steelGrey = {
  dark90: '#010F44',
  dark80: '#1B2656',
  dark60: '#123068',
  dark40: '#2C487D',
  dark20: '#526A98',
  default: '#7B89B7',
  light20: '#95A1C6',
  light40: '#B5BDD3',
  light60: '#C7CFE5',
  light80: '#DDE1EE',
  light90: '#EFF1F7',
  light95: '#F8F9FC',
};

const indigo = {
  dark90: '#0C0C3C',
  dark80: '#101050',
  dark60: '#181879',
  dark40: '#2020A1',
  dark20: '#2828C9',
  default: '#4851DC',
  light20: '#6D74E3',
  light40: '#9197EA',
  light60: '#B6B9F1',
  light80: '#DADCF8',
  light90: '#EDEEFB',
  light95: '#F6F6FD',
};

const neonBlue = {
  dark90: '#091D43',
  dark80: '#0C2759',
  dark60: '#0F357D',
  dark40: '#194EB2',
  dark20: '#1F62DE',
  default: '#2B9DFE',
  light20: '#70B1F9',
  light40: '#94C4FA',
  light60: '#B7D8FC',
  light80: '#DBEBFD',
  light90: '#EDF5FE',
  light95: '#F6FAFF',
};

const electricTomato = {
  dark90: '#400E1A',
  dark80: '#551223',
  dark60: '#7F1C34',
  dark40: '#AA2546',
  dark20: '#D42E57',
  default: '#EB4160',
  light20: '#EF6780',
  light40: '#F38DA0',
  light60: '#F7B3BF',
  light80: '#FBD9DF',
  light90: '#FDECEF',
  light95: '#FEF5F7',
};

const honey = {
  dark90: '#4A3100',
  dark80: '#644200',
  dark60: '#956200',
  dark40: '#C78300',
  dark20: '#FFB01A',
  default: '#FFC736',
  light20: '#FFD25E',
  light40: '#FFDD87',
  light60: '#FFE9AF',
  light80: '#FFF4D7',
  light90: '#FFF9EB',
  light95: '#FFFCF5',
};

const teal = {
  dark90: '#1B3D3B',
  dark80: '#2D6562',
  dark60: '#377976',
  dark40: '#408D8A',
  dark20: '#52B6B1',
  default: '#5BCAC5',
  light20: '#7CD5D1',
  light40: '#9DDFDC',
  light60: '#BDEAE8',
  light80: '#DEF4F3',
  light90: '#EFFAF9',
  light95: '#F7FCFC',
};

const colors = {
  white: { default: '#fff' },
  steelGrey,
  indigo,
  neonBlue,
  electricTomato,
  honey,
  teal
};

const theme = {
  colors: {
    ...colors,

    primary: colors.indigo,
    secondary: colors.neonBlue,
    brand: colors.neonBlue,
    error: colors.electricTomato,
    danger: colors.electricTomato,
    warning: colors.honey,
    success: colors.teal,
    neutral: colors.steelGrey,

    text: colors.steelGrey.dark90,
    textBody: colors.steelGrey.dark90,
  },
  breakpoints,
  spacings,
  fonts
};

export { breakpoints, colors, spacings };
export default theme;
