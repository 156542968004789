import styled from 'styled-components';

const Monogram = styled.div<{$backgroundImageUrl: string;}>`
  display: flex;
  background-color: ${props => props.$backgroundImageUrl ? 'none' : 'var(--Colors-Secondary-Lime-lime-80, #F0FDD3)'};
  color: var(--Colors-Secondary-Lime-lime, #ADE82F);
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  border: 1px solid var(--Colors-Primary-Eerie-Black-P, #1D1D1B);

  ${props => props.$backgroundImageUrl && `
    background-image: url('${props.$backgroundImageUrl}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  `};
`;

export default Monogram;