import {useUsers} from '../../../_hooks/user/use-users';
import * as React from 'react';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import {PageContainer} from '../../_ui/page';
import {ROUTES} from '../../../router/routes';
import {useNavigate} from 'react-router-dom';
import {useUserRequests} from '../../../_hooks/user-request/use-user-requests';
import {UserRequestAcceptedCaption} from '../_commons/captions';
import {useTranslation} from 'react-i18next';

const COLUMNS = (handleCellClick, t) => [
  {
    label: 'ID',
    renderCell: (item) => item.id.toString(),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'User ID',
    renderCell: (item) => item.user_id.toString(),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Statut',
    renderCell: (item) => (
      <UserRequestAcceptedCaption
        className={item.accepted?.toString()}
      >
        {t([`enums.user_request.accepted.${item.accepted?.toString()}`, 'enums.user_request.accepted.null'])}
      </UserRequestAcceptedCaption>
    ),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Prénom',
    renderCell: (item) => item.user?.first_name,
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Nom',
    renderCell: (item) => item.user?.last_name,
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'École',
    renderCell: (item) => item.school_name,
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Pays',
    renderCell: (item) => item.school_country,
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Année',
    renderCell: (item) => item.school_year,
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: 'Date Demande',
    renderCell: (item) =>
      new Date(item.created_at).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
];

const UserRequestsTable = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {userRequests, isLoading} = useUserRequests({
    filters: {
      category: ['student']
    },
    sorting: [
      {
        name: 'created_at',
        direction: 'desc',
      },
    ],
  });

  const handleCellClick = (data) => {
    navigate(ROUTES.USER_REQUESTS(data.id));
  };

  const data = {nodes: userRequests?.list || []};

  const theme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns: 50px 100px 90px repeat(5, minmax(120px, 1fr)) minmax(120px, 250px);
      `,
      HeaderCell: `
        z-index: 0;
      `,
    },
  ]);

  return (
    <div style={{textAlign: 'left'}}>
      <CompactTable
        columns={COLUMNS(handleCellClick, t)}
        data={data}
        theme={theme}
        layout={{
          custom: true,
          horizontalScroll: true,
          fixedHeader: true,
        }}
      />
    </div>
  );
};

const UserRequestsPage = () => (
  <PageContainer
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      gap: 20,
      height: 'calc(100svh - 120px)',
    }}
  >
    <h1>Demandes Étudiantes</h1>
    <UserRequestsTable/>
  </PageContainer>
);

export default UserRequestsPage;