import {
  // LoginFormFields,
  mapLoginFormFieldsToApi,
} from "../../utils/forms/login";
// import {
//   ResetPasswordFormFields,
//   mapResetPasswordFormFieldsToApi,
// } from "../../utils/forms/reset-password";
// import {
//   ResetPasswordFields,
//   ResetPasswordSuccess,
// } from "../../utils/forms/reset-password/types";
// import {
//   mapSignupFormFieldsToApi,
//   SignupFormFields,
// } from "../../utils/forms/signup";
import { revokeAuthToken } from "../../utils/token";

import { apiNemoClient as apiClient } from "../config/clients";
import { API_NEMO_ROUTES } from "../config/config";
import {
  APIUser,
  LoginFields,
  LoginSuccess,
  SignupFields,
} from "./types";
import {mapSignupFormFieldsToApi} from '../../utils/forms/login/services';
import {ROUTES} from '../../../router/routes';

export function signup(formData): Promise<APIUser> {
  const formattedPayload = mapSignupFormFieldsToApi(formData);
  return apiClient.post<SignupFields, APIUser>(
    API_NEMO_ROUTES.SIGNUP,
    formattedPayload
  );
}

// export function login(formData: LoginFormFields): Promise<LoginSuccess> {
export function login(formData): Promise<LoginSuccess> {
  const formattedPayload = mapLoginFormFieldsToApi(formData);
  return apiClient.post<LoginFields, LoginSuccess>(
    API_NEMO_ROUTES.LOGIN,
    formattedPayload
  );
}

export async function logout(withApiCall = true): Promise<void> {
  if (withApiCall) {
    await apiClient.delete(API_NEMO_ROUTES.LOGOUT);
  }
  await revokeAuthToken();
  apiClient.unsetToken();
  window.location.href = ROUTES.HOME();
}

export function setAuthToken(token: string): void {
  //Save the token in axios instance
  apiClient.setToken(token);
}


export function resetPassword(
  formData
): Promise<any> {
  const formattedPayload = mapSignupFormFieldsToApi(formData);
  return apiClient.post(
    API_NEMO_ROUTES.PASSWORD,
    formattedPayload
  );
}

export function updatePassword(
  formData
): Promise<any> {
  const formattedPayload = mapSignupFormFieldsToApi(formData);
  return apiClient.put(
    API_NEMO_ROUTES.PASSWORD,
    formattedPayload
  );
}

export function confirmUser(
  formData
): Promise<any> {
  const {confirmation_token} = formData;
  return apiClient.get(
    `${API_NEMO_ROUTES.USER_CONFIRMATION}?api_v1_users_user[confirmation_token]=${confirmation_token}`,
  );
}

export function sendConfirmationInstructionsToUser(
  formData
): Promise<any> {
  const formattedPayload = mapSignupFormFieldsToApi(formData);
  return apiClient.post(
    `${API_NEMO_ROUTES.USER_CONFIRMATION}`,
    formattedPayload
  );
}
