import {PageContainer} from '../../_ui/page';
import TabbedNavigation from '../../_ui/tabbed-navigation';
import {useState} from 'react';
import {useDeletePathology, usePathologies} from '../../../_hooks/pathology/use-pathologies';
import {APIListPayload} from '../../../_services/api/_helpers/api-list-types';
import {ReactComponent as TrashIcon} from '../../../_icons/trash.svg';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../../../router/routes';
import {useTheme} from '@table-library/react-table-library/theme';
import {getTheme} from '@table-library/react-table-library/baseline';
import {CompactTable} from '@table-library/react-table-library/compact';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {PathologyStatusCaption, PathologyRecurrentCaption} from '../_commons/captions';

const DeleteCell = ({id, onDelete}) => {
  const {deletePathology} = useDeletePathology();
  return (
    <TrashIcon
      color={"#FF453A"}
      onClick={async () => {
        if (id && window.confirm("Voulez-vous vraiment supprimer cet élément?")) {
          await deletePathology(id);
          onDelete?.();
        }
      }}
      // width={40}
      height={"100%"}
      style={{cursor: 'pointer'}}
    />
  );
};

const PathologiesTable = ({payload} : {payload?: APIListPayload}) => {
  const {getPathologies, pathologies} =  usePathologies(payload);
  const navigate = useNavigate();
  const {t} = useTranslation();

  const handleCellClick = (data) => {
    navigate(ROUTES.PATHOLOGIES(data.id));
  };

  const data = {nodes: pathologies?.list || []};

  const theme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns: 60px 90px minmax(350px, 1fr) minmax(100px, 120px) minmax(120px, 160px) 50px;
      `,
      HeaderCell: `
        z-index: 0;
      `,
    },
  ]);

  const COLUMNS = [
    {
      label: 'ID',
      renderCell: (item) => item.id.toString(),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: 'left'
        }
      }
    },
    {
      label: 'Statut',
      renderCell: (item) => (
        <PathologyStatusCaption
          className={item.status}
        >
          {t(`enums.pathology.status.${item.status}`)}
        </PathologyStatusCaption>
      ),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: 'left'
        }
      }
    },
    {
      label: 'Label',
      renderCell: (item) => <span title={item.text}>{item.text}</span>,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: 'left'
        }
      }
    },
    {
      label: 'Récurrent',
      renderCell: (item) => (
        <PathologyRecurrentCaption
          className={item.recurrent.toString()}
        >
          {t(`enums.pathology.recurrent.${item.recurrent.toString()}`)}
        </PathologyRecurrentCaption>
      ),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: 'left'
        }
      }
    },
    {
      label: 'Date de création',
      renderCell: (item) => new Date(item.created_at).toLocaleDateString('fr-fr'),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: 'left'
        }
      }
    },
    {
      label: '',
      renderCell: (item) => <DeleteCell id={item.id} onDelete={() => setTimeout(() => getPathologies(), 200)}/>,
    },
  ];

  return (
    <CompactTable
      columns={COLUMNS}
      data={data}
      theme={theme}
      layout={{
        custom: true,
        horizontalScroll: true,
        fixedHeader: true,
      }}
    />
  );
};

const categories = {
  neurology: <span>neurology</span>,
  musculotendinous: <span>musculotendinous</span>,
  osteoarticular: <span>osteoarticular</span>,
  other: <span>other</span>,
};

const PathologiesPage = () => {
  const [activeTab, setActiveTab] = useState<string>();
  const {t} = useTranslation();

  const items = Object.keys(categories).map((category) => (
    {
      key: category,
      element: <span>{t(`enums.pathology.category.${category}`)}</span>,
      onClick: () => setActiveTab(category),
    }
  ));

  const categoryKey = activeTab || items[0]?.key;

  return (
    <PageContainer
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: 20,
      }}
    >
      <TabbedNavigation
        items={items}
        defaultActive={items.findIndex((item) => item.key === categoryKey)}
      />
      <div style={{height: '100%'}}>
        {categoryKey && (
          <PathologiesTable
            payload={{filters: {category: [categoryKey]}}}
          />
        )}
      </div>
    </PageContainer>
  );
};

export default PathologiesPage;